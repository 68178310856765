import { BoardCardItemResponse } from '@features/Dashboard/types';

import { atom } from 'jotai';

const selectedCardsAtom = atom<BoardCardItemResponse[]>([]);

const enteredMessageAtom = atom<string>('');

export {
  selectedCardsAtom,
  enteredMessageAtom
};