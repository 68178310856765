import { TelegramWindowContextType, useTelegram } from '../../../services';
import { useQuery } from '@tanstack/react-query';

import { Maybe } from '../../../types';

function blobToBase64(blob: Maybe<Blob | string>): Promise<Maybe<string>> {
    if (!blob) return Promise.resolve(null);
    if (typeof blob === 'string') return Promise.resolve(blob);
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
    });
}

const loadImage = async (tg: Maybe<TelegramWindowContextType>, url: string) => {
    if (!tg) return null;
    if (['null', 'undefined'].some((v) => url.includes(v))) return null;

    try {
        const res = await tg.methods.proxy.downloadMedia({
            url,
            mediaFormat: 0,
            isHtmlAllowed: false,
        });

        const dataUrl = await blobToBase64(res?.dataBlob);

        return dataUrl || null;
    } catch (error) {
        console.error('Error loading image', error);
        return null;
    }
};

export const useAvatar = (
    id?: string,
    avatarHash?: string,
    placeholder?: string,
) => {
    const tg = useTelegram();

    const avatar = useQuery({
        queryKey: ['avatar', id, avatarHash],
        queryFn: () => loadImage(tg, `avatar${id}?${avatarHash}`),
        enabled: !!tg && !!avatarHash,
    });

    return (
        avatar.data ??
        (placeholder
            ? `https://api.dicebear.com/9.x/initials/svg?seed=${placeholder}`
            : null)
    );
};
