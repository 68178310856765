import { fetchChatFolders } from '../telegram-api';
import { useQuery } from '@tanstack/react-query';
import { Alerter } from '../../../utils';
import { useTelegram } from '../context';

// UNUSED after onboarding refactoring, remove?
/**
 * This query is used to get the folders from the Telegram API.
 */
export const useGetTelegramFolders = (isEnabled?: boolean) => {
    const tg = useTelegram();
    const methods = tg?.methods;    

    return useQuery({
        queryKey: ['tg-api-folders'],
        queryFn: () => {
            console.log('======check tg-api-folders');
            if (!methods) {
                throw new Error('methods not provided');
            }
            
            return fetchChatFolders(methods);
        },
        retryDelay: 1000,
        retry(failureCount) {
            console.log({failureCount});
            
            if (failureCount >= 5) {
                Alerter.error(
                    'Telegram has not fully synchronized folders and chats on its side yet. Please try again later.',
                );
                return false;
            }
            return true;
        },
        refetchOnWindowFocus: false,
        enabled: !!methods && isEnabled,
    });
};
