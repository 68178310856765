import { z } from 'zod';

export const BoolString = z
    .union([
        z.literal('true'),
        z.literal('false'),
        z.literal('1'),
        z.literal('0'),
        z.literal(''),
    ])
    .transform((s) => {
        switch (s) {
            case 'true':
            case '1':
                return true;
            default:
                return false;
        }
    });
