import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from '../../../api/api.base';
import { QueryKeys } from '../../../constants';
import { Alerter } from '../../../utils';
import { useTgFolders } from '../../../hooks/useTgFolders';
import { useGetMatchedWorkspaceId } from '../../../hooks';


// UNUSED with cycles useSyncFolders --- useTgFolders, remove with old wync-chats|onboarding?
export const useSyncFolders = ({ onFinish }: { onFinish: VoidFunction }) => {
    const queryClient = useQueryClient();
    const workspaceId = useGetMatchedWorkspaceId();
    const tgFolders = useTgFolders();

    return useMutation({
        mutationKey: [QueryKeys.SYNC_FOLDERS],
        mutationFn: (payload?: { skip: boolean }) => {
            const allFolders = tgFolders.all;
            console.log({allFolders});
            
            if (!allFolders && !payload?.skip) {
                throw new Error('Folders are undefined');
            }

            const selectedFolders = tgFolders.selected.map((id) =>
                allFolders?.find((folder) => folder.telegramFolderId === id),
            );

            const data = {
                workspaceId: workspaceId,
                folders: payload?.skip
                    ? []
                    : selectedFolders.map((folder) => ({
                          telegramFolderId: folder?.telegramFolderId,
                          chats: folder?.chats.map((i) => ({
                              telegramChatId: i.id,
                              name: i.chat?.title || '',
                          })),
                      })),
            };

            return API.post('/api/cards/sync', data);
        },
        onSuccess: (data, variables) => {
            console.log('data', data);
            if (!data.success) {
                Alerter.error(data?.errors?.[0]?.message);
                return;
            }

            onFinish();

            if (variables?.skip) {
                return;
            }
            Alerter.success('Chats sync settings updated!');
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.GET_WORKSPACE_CARDS_STATUSES],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.GET_BOARD_CARDS],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.TG_CHATS],
            });
            queryClient.refetchQueries({
                queryKey: [QueryKeys.TG_CHATS],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.GET_TG_FOLDERS, workspaceId],
            });
        },
    });
};
