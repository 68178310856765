import { useEffect, useState } from 'react';
import { useTelegram } from '../services';
import { fetchTgUser } from '../services/telegram/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useTelegramLock } from '../services/telegram/lockContext';

/**
 * @deprecated use useAvatar instead
 */
export const useUserAvatars = (ids?: number[]) => {
    const [avatars, setAvatars] = useState<Record<number, string>>({});
    const lock = useTelegramLock();
    const queryClient = useQueryClient();
    const tg = useTelegram();

    useEffect(() => {
        if (ids?.length && tg) {
            ids.forEach(async (id) => {
                try {
                    if (avatars[id] !== undefined) return;

                    const userInfo = await lock.scheduleEvent((tg) =>
                        fetchTgUser(queryClient, tg, id),
                    );

                    setAvatars((prevAvatars) => ({
                        ...prevAvatars,
                        [id]:
                            userInfo?.userFullInfo?.profilePhoto?.thumbnail
                                ?.dataUri ||
                            userInfo?.userFullInfo?.fallbackPhoto?.thumbnail
                                ?.dataUri ||
                            '',
                    }));
                } catch (error) {
                    setAvatars((prevAvatars) => ({ ...prevAvatars, [id]: '' }));
                }
            });
        }
    }, [ids, tg]);

    return avatars;
};
