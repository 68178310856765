import React, { useMemo } from 'react';
import { HStack, IconButton, useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { NewActionMenu } from '../NewActionMenu';
import { ReminderPopup, TaskPopup } from '../../../../components';
import { Icon } from '../../../../../../components';
import { useUpdateCardTrackStatus } from '../../../../../../queries';
import { RootStore } from '../../../../../../redux/createStore';
import { useGetBoardCards } from '../../../../../Dashboard/queries';
import { colors } from '../../../../../../theme/colors';

interface ITextareaActionsProps {
    cardId: number;
    workspaceId?: number;
}

export const TextareaActions: React.FC<ITextareaActionsProps> = ({
    cardId,
    workspaceId,
}) => {
    const { state } = useLocation();
    const { data: cardsData } = useGetBoardCards();

    const { chatTelegramId } = state || {};

    const { chats } = useSelector((state: RootStore) => state.telegramState);
    const { mutate, isPending } = useUpdateCardTrackStatus(false);
    const lastTgChatMessageDate = useMemo(() => {
        if (!chats[`${chatTelegramId}`]) {
            return null;
        }
        return chats[chatTelegramId].lastMessage?.date || null;
    }, [chatTelegramId, chats]);

    const {
        isOpen: isNewTaskPopupOpen,
        onOpen: onOpenNewTaskPopup,
        onToggle: onToggleNewTaskPopup,
        onClose: onCloseNewTaskPopup,
    } = useDisclosure();

    const {
        isOpen: isReminderPopupOpen,
        onOpen: onOpenReminderPopup,
        onToggle: onToggleReminderPopup,
        onClose: onCloseReminderPopup,
    } = useDisclosure();

    const trackedLastTelegramMessageDate = useMemo(
        () =>
            cardsData?.find((elem) => elem.cardId === cardId)
                ?.trackedLastTelegramMessageUnixTime || null,
        [cardsData, cardId],
    );

    const onChangeTrackState = (
        currentMessageId: number | null,
        cardMessageId: number | null,
    ) => {
        const isLastCardMessageIgnored = currentMessageId === cardMessageId;
        mutate({
            cardId,
            workspaceId: workspaceId || 0,
            trackedLastTelegramMessageUnixTime: isLastCardMessageIgnored
                ? null
                : currentMessageId,
        });
    };

    return (
        <HStack spacing="8px" align="flex-start" py="16px">
            <NewActionMenu cardId={cardId} workspaceId={workspaceId} />
            <TaskPopup
                isOpen={isNewTaskPopupOpen}
                onClose={onCloseNewTaskPopup}
                chatTelegramId={chatTelegramId}
                onToggle={onToggleNewTaskPopup}
                cardId={cardId}
            >
                <IconButton
                    aria-label="Create task"
                    title="Create task"
                    bg={isNewTaskPopupOpen ? 'transparentLight.10' : undefined}
                    minW="auto"
                    boxSize="24px"
                    borderRadius="4px"
                    variant="outline"
                    onClick={onOpenNewTaskPopup}
                >
                    <Icon
                        name="check"
                        height="16"
                        width="16"
                        color={colors.secondary}
                    />
                </IconButton>
            </TaskPopup>
            <ReminderPopup
                isOpen={isReminderPopupOpen}
                onClose={onCloseReminderPopup}
                onToggle={onToggleReminderPopup}
                cardId={cardId}
            >
                <IconButton
                    aria-label="Create reminder"
                    title="Create reminder"
                    bg={isReminderPopupOpen ? 'transparentLight.10' : undefined}
                    minW="auto"
                    boxSize="24px"
                    borderRadius="4px"
                    variant="outline"
                    onClick={onOpenReminderPopup}
                >
                    <Icon
                        name="calendar"
                        height="16"
                        width="16"
                        color={colors.secondary}
                    />
                </IconButton>
            </ReminderPopup>

            <IconButton
                aria-label="To track conversation on/off"
                title={
                    trackedLastTelegramMessageDate === lastTgChatMessageDate
                        ? 'Mark as answered'
                        : 'Mark as unanswered'
                }
                minW="auto"
                boxSize="24px"
                variant="outline"
                bg="transparent"
                borderRadius="4px"
                isLoading={isPending}
                onClick={() =>
                    onChangeTrackState(
                        lastTgChatMessageDate,
                        trackedLastTelegramMessageDate,
                    )
                }
            >
                <Icon
                    name={
                        trackedLastTelegramMessageDate === lastTgChatMessageDate
                            ? 'eye'
                            : 'eye-off'
                    }
                    height="16"
                    width="16"
                />
            </IconButton>
        </HStack>
    );
};
