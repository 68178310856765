import { useQuery } from '@tanstack/react-query';
import API from '../../../api/api.base';
import { BoardCardItemResponse } from '../types';
import { QueryKeys } from '../../../constants';
import { useGetMatchedWorkspaceId } from '../../../hooks';

export const useGetBoardCards = () => {
	const workspaceId = useGetMatchedWorkspaceId();	

	return useQuery({
		queryKey: [QueryKeys.GET_BOARD_CARDS, workspaceId],
		queryFn: () => API.get(`/api/workspaces/${workspaceId}/cards`, {}),
		refetchOnWindowFocus: false,
		enabled: !!workspaceId,
		refetchInterval: 30 * 1000, //refetch data every 30s
		select: data => (data.value?.cards ?? []) as BoardCardItemResponse[],
	});
};
