import React from 'react';
import { useTelegramLock } from '../../services/telegram/lockContext';
import { Button, Text } from '@chakra-ui/react';

export const IframeLockWrapper: React.FCC<{ iframeOpened: boolean }> = ({
    children,
    iframeOpened,
}) => {
    const lock = useTelegramLock();

    React.useEffect(() => {
        if (iframeOpened) {
            const releaseUiLock = lock.acquireUiLock({
                breakLockTimeoutMs: 3000,
            });
            return () => releaseUiLock();
        }
    }, [iframeOpened]);

    const locked = lock.isLocked && iframeOpened && !lock.isLockedByUi;
    return (
        <React.Fragment>
            {children}
            {locked ? <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        pointerEvents: 'all',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Text mb={'1rem'}>
                        Performing some operations...
                    </Text>

                    <Button
                        size={'sm'}
                        variant={'ghost'}
                        onClick={() => lock.releaseLock()}
                    >
                        Pause
                    </Button>
                </div> : null}
        </React.Fragment>
    );
};
