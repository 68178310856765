import React, { useEffect, useRef } from 'react';
import { TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { InviteTeam, CreateWorkspace, SelectWorkspace } from './steps';
import { ModalWrapper } from '../../components';
import { ChatsSync } from './steps/ChatsSync';
import { RootStore } from '../../redux/createStore';
import {
    setOnboardingActiveStep,
    setOnboardingVisibility,
    setOnboardingCanBeClosed,
    resetOnboardingState,
    setSyncSettingsModalOpen,
} from '../../redux/reducers/onboarding.reducer';
import { setOnboardedStatus } from '../../redux/reducers/auth.reducer';
import { OnboardingSteps } from '../../types';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '../../constants';

export const OnboardingModal: React.FC = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const modalInstanceRef = useRef(false);

    const { isOpen, activeStep } = useSelector(
        (state: RootStore) => state.onboarding,
    );
    const { workSpace } = useSelector((state: RootStore) => state.auth.user);

    useEffect(() => {
        if (isOpen && modalInstanceRef.current) {
            dispatch(setOnboardingVisibility(false));
            return;
        }

        if (isOpen) {
            modalInstanceRef.current = true;
        } else {
            modalInstanceRef.current = false;
        }
    }, [isOpen, dispatch]);

    const onClose = () => {
        dispatch(setOnboardingVisibility(false));
        dispatch(setOnboardingActiveStep(OnboardingSteps.CREATE_WORKSPACE));
        dispatch(setOnboardingCanBeClosed(false));
    };

    const onFinish = () => {
        dispatch(setOnboardedStatus(true));

        dispatch(setOnboardingVisibility(false));
        dispatch(setSyncSettingsModalOpen(false));
        dispatch(setOnboardingActiveStep(OnboardingSteps.CREATE_WORKSPACE));
        dispatch(resetOnboardingState());

        if (!workSpace?.id) {
            return;
        }

        queryClient.invalidateQueries({
            queryKey: [QueryKeys.GET_TG_FOLDERS, workSpace.id],
        });
        queryClient.invalidateQueries({
            queryKey: [QueryKeys.GET_BOARD_CARDS, workSpace.id],
        });
    };

    return (
        <ModalWrapper
            isOpen={isOpen}
            onClose={onClose}
            modalContentProps={{ p: '40px' }}
            modalBodyProps={{ p: 0 }}
        >
            <Tabs index={activeStep}>
                <TabPanels>
                    <TabPanel p={0}>
                        <SelectWorkspace />
                    </TabPanel>

                    <TabPanel p={0}>
                        <CreateWorkspace />
                    </TabPanel>

                    <TabPanel p={0}>
                        <InviteTeam
                            isActiveStep={
                                activeStep === OnboardingSteps.INVITE_TEAM
                            }
                        />
                    </TabPanel>

                    <TabPanel p={0}>
                        <ChatsSync onFinish={onFinish} includeSkip={true} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </ModalWrapper>
    );
};
