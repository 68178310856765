import React from 'react';
import { MenuItem, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { Icon } from '../../../Icon/Icon';
import { colors } from '../../../../theme/colors';
import {
	setOnboardingVisibility,
	setOnboardingCanBeClosed,
	setOnboardingActiveStep,
} from '../../../../redux/reducers/onboarding.reducer';
import { OnboardingSteps } from '../../../../types';

export const AddWorkspaceButton: React.FC = () => {
	const dispatch = useDispatch();

	const onOpenCreateWorkspaceModal = () => {
		dispatch(setOnboardingActiveStep(OnboardingSteps.CREATE_WORKSPACE));
		dispatch(setOnboardingCanBeClosed(true));
		dispatch(setOnboardingVisibility(true));
	};

	return (
		<MenuItem
			height="40px"
			onClick={onOpenCreateWorkspaceModal}
			px="8px"
			gap="8px"
			_hover={{ bg: 'transparentLight.10' }}
			_focus={{ bg: 'transparentLight.10' }}
		>
			<Icon
				name="plus"
				height="16px"
				width="16px"
				color={colors.secondary}
			/>
			<Text color="secondary" fontSize="13px" fontWeight={400}>
				Add workspace
			</Text>
		</MenuItem>
	);
};
