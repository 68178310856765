import { useEffect } from 'react';

export const useUnsavedChangesChecker = (isUnsavedChanges: boolean) => {
  useEffect(() => {
    const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
      if (!isUnsavedChanges) return;

      event.preventDefault();

      // Included for legacy support, e.g. Chrome/Edge < 119
      // eslint-disable-next-line no-param-reassign
      event.returnValue = true;
    };

    window.addEventListener('beforeunload', beforeUnloadHandler);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [isUnsavedChanges]);
};
