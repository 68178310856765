import { useSelector, useDispatch } from 'react-redux';
import { ModalWrapper } from '../../components';
import { ChatsSync } from '../Onboarding/steps/ChatsSync';
import { RootStore } from '../../redux/createStore';
import { setSyncSettingsModalOpen } from '../../redux/reducers/onboarding.reducer';
import { QueryKeys } from '../../constants';
import { useQueryClient } from '@tanstack/react-query';

export const SyncSettingsModal = () => {
    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const { syncSettingsModalOpen } = useSelector(
        (state: RootStore) => state.onboarding,
    );

    const { workSpace } = useSelector((state: RootStore) => state.auth.user);
    
    return (
        <ModalWrapper
            isOpen={syncSettingsModalOpen}
            onClose={() => dispatch(setSyncSettingsModalOpen(false))}
        >
            <ChatsSync
                includeSkip={true}
                onFinish={() => {
                    dispatch(setSyncSettingsModalOpen(false));

                    if (!workSpace?.id) {
                        return;
                    }

                    queryClient.invalidateQueries({
                        queryKey: [QueryKeys.GET_TG_FOLDERS, workSpace.id],
                    });

                    queryClient.invalidateQueries({
                        queryKey: [QueryKeys.GET_BOARD_CARDS, workSpace.id],
                    });
                }}
            />
        </ModalWrapper>
    );
};
