import { z } from 'zod';
import { AppEnv } from '../App.const';
import { BoolString } from '../utils/validation';

export const flagSchema = z.object({
    batch_send: BoolString,
    new_auth: BoolString
}).strict();

export type FlagsSchema = z.infer<typeof flagSchema>;
export type FlagsConfig = Record<AppEnv, FlagsSchema>;
