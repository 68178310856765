import { MouseEvent, ReactNode } from 'react';
import { IconButton, ButtonProps } from '@chakra-ui/react';
import { EditorState, useEditorState } from '../editor/EditorContext';
import { Icon } from '../../Icon/Icon';
import { ensureProtocol } from '../../../../../telegram-tt/src/util/ensureProtocol';

export const BtnBold = createButton(
	'Bold',
	<Icon name="bold" height="9px" width="7px" />,
	'bold',
);

// export const BtnBulletList = createButton(
// 	'Bullet list',
// 	<UnorderedListIcon />,
// 	'insertUnorderedList',
// );

export const BtnClearFormatting = createButton(
	'Clear formatting',
	'T̲ₓ',
	'removeFormat',
);

export const BtnItalic = createButton(
	'Italic',
	<Icon name="italic" height="9px" width="4px" />,
	'italic',
);

export const BtnStrikeThrough = createButton(
	'Strike through',
	<s>ab</s>,
	'strikeThrough',
);

export const BtnLink = createButton(
	'Link',
	<Icon name="link" width="14px" height="14px" />,
	({ $selection }) => {
		if ($selection?.nodeName === 'A') {
			document.execCommand('unlink');
		} else {
			const linkUrl = prompt('URL', '') || undefined;
			const formattedLinkUrl = (ensureProtocol(linkUrl) || '')
				.split('%')
				.map(encodeURI)
				.join('%');
			// eslint-disable-next-line no-alert
			document.execCommand(
				'createLink',
				false,
				formattedLinkUrl || undefined,
			);
		}
	},
);

// export const BtnNumberedList = createButton(
// 	'Numbered list',
// 	<OrderedListIcon />,
// 	'insertOrderedList',
// );

export const BtnRedo = createButton('Redo', '↷', 'redo');

export const BtnUnderline = createButton(
	'Underline',
	<span style={{ textDecoration: 'underline' }}>𝐔</span>,
	'underline',
);

export const BtnUndo = createButton('Undo', '↶', 'undo');

export function createButton(
	title: string,
	content: ReactNode,
	command: ((state: EditorState) => void) | string,
) {
	ButtonFactory.displayName = title.replace(/\s/g, '');

	return ButtonFactory;

	function ButtonFactory(props: ButtonProps) {
		const editorState = useEditorState();
		const { $el, $selection } = editorState;

		let active = false;
		if (typeof command === 'string') {
			active = !!$selection && document.queryCommandState(command);
		}

		function onAction(e: MouseEvent<HTMLButtonElement>) {
			e.preventDefault();

			if (document.activeElement !== $el) {
				$el?.focus();
			}

			if (typeof command === 'function') {
				command(editorState);
			} else {
				document.execCommand(command);
			}
		}

		if (editorState.htmlMode) {
			return null;
		}

		return (
			<IconButton
				aria-label={title}
				type="button"
				bg={active ? '#00000014' : 'white'}
				minW="auto"
				borderRadius="4px"
				boxSize="24px"
				variant="outline"
				onMouseDown={onAction}
				{...props}>
				{content}
			</IconButton>
		);
	}
}
