import { useEffect, useMemo } from 'react';
import {
    Box,
    Tabs,
    TabList,
    TabPanels,
    TabPanel,
    TabIndicator,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { TabWithIcon } from '../../components';
import {
    InternalChat,
    Activity,
    Templates,
    Tasks,
    Reminders,
    Info,
} from './tabs';
import { RootStore } from '../../redux/createStore';
import { useTelegram } from '../../services';
import { useGetCardActivitiesOptions } from './queries';
import { useUserAvatars, useGetWorkspaceId } from '../../hooks';
import { ApiResponse } from '../../types';
import { QueryKeys, CHAT_TABS, TEAM_CHAT_TABS } from '../../constants';
import { resetChatTeamMembersData } from '../../redux/reducers/chat-state.reducer';
import { useGetBoardCards } from '../Dashboard/queries';
import { useParams } from '../../router';
import { useChats } from '../../hooks/useChats';

const ChatPage = () => {
    const { chatId: id } = useParams('/:workspaceId/chat/:chatId');
    const workspaceId = useGetWorkspaceId();
    const tg = useTelegram();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { data: cards, isLoading } = useGetBoardCards();

    const { isSynced } = useSelector((state: RootStore) => state.syncState);
    const { workSpace } = useSelector((state: RootStore) => state.auth.user);

    const cardId = id ? +id : 0;

    const isTeamChat = useMemo(
        () => workSpace?.teamChat?.cardId === cardId,
        [workSpace, cardId],
    );

    const { chatTelegramId, cardTitle, cardLabels } = useMemo(() => {
        const currentCard = cards?.find((card) => card.cardId === cardId);

        const chatData = {
            cardTitle: currentCard?.cardName || '',
            chatTelegramId: 0,
            cardLabels: [],
        };

        return isTeamChat && workSpace?.teamChat?.id
            ? { ...chatData, chatTelegramId: workSpace.teamChat.id }
            : {
                  ...chatData,
                  cardLabels: currentCard?.labels || [],
                  chatTelegramId: currentCard?.chatTelegramId || 0,
              };
    }, [cards, cardId, isTeamChat, workSpace?.teamChat?.id]);

    const chat = useChats().getChatInfo(chatTelegramId.toString());

    const idToFetch = useMemo(
        () => (isTeamChat ? 0 : id ? +id : 0),
        [id, isTeamChat],
    );

    const teamTelegramIds = queryClient.getQueryData<
        ApiResponse<{ telegramUserIds: number[] }>
    >([QueryKeys.GET_WORKSPACE_TELEGRAM_IDS, workspaceId]);

    useUserAvatars(teamTelegramIds?.value?.telegramUserIds);

    //cover team chat
    useGetCardActivitiesOptions(
        !!workspaceId && !!idToFetch,
        workspaceId,
        idToFetch,
    );

    useEffect(() => {
        if (chatTelegramId && tg && isSynced) {
            tg?.actions.proxy.openChat({
                id: chatTelegramId.toString(),
            });
            tg?.iframe.contentWindow?.focus();

            return () => {
                dispatch(resetChatTeamMembersData());
            };
        }
    }, [tg, chatTelegramId, dispatch, isSynced]);

    return (
        <Box as="section" id="chat-layout" w="100%" h="100%">
            <Tabs
                variant="unstyled"
                backgroundColor="white"
                borderRadius="4px"
                borderWidth="1px"
                boxShadow="0px 0px 4px 0px #0000000A"
                borderColor="gray.20"
                height={'100%'}
            >
                <TabList
                    borderBottomWidth="1px"
                    borderBottomColor="gray.15"
                    columnGap="8px"
                >
                    {(isTeamChat ? TEAM_CHAT_TABS : CHAT_TABS).map(
                        (elem, index) => (
                            <TabWithIcon
                                key={index}
                                iconName={elem.iconName}
                                label={elem.label}
                            />
                        ),
                    )}
                </TabList>

                <TabIndicator
                    // mt="-1.5px"
                    height="1px"
                    bg="primary"
                    borderRadius="1px"
                />

                <TabPanels height="calc(100vh - 105px)">
                    {!isTeamChat ? (
                        <TabPanel p="0" height="100%">
                            <InternalChat
                                key={id}
                                cardId={cardId}
                                workspaceId={workspaceId}
                            />
                        </TabPanel>
                    ) : null}

                    {!isTeamChat ? (
                        <TabPanel p="0" height="100%">
                            <Info
                                cardTitle={cardTitle}
                                workspaceId={workspaceId}
                                cardId={cardId}
                                isCardDataLoading={isLoading}
                                cardLabels={cardLabels}
                            />
                        </TabPanel>
                    ) : null}

                    {!isTeamChat ? (
                        <TabPanel p="0" height="100%">
                            <Activity
                                key={id}
                                workspaceId={workspaceId}
                                cardId={cardId}
                            />
                        </TabPanel>
                    ) : null}

                    {!isTeamChat ? (
                        <TabPanel p="0">
                            <Templates
                                cardId={cardId}
                                isUserHasAccess={!!chat}
                            />
                        </TabPanel>
                    ) : null}

                    <TabPanel p="0">
                        <Tasks workspaceId={workspaceId} cardId={cardId} />
                    </TabPanel>

                    {!isTeamChat ? (
                        <TabPanel p="0">
                            <Reminders
                                workspaceId={workspaceId}
                                cardId={cardId}
                            />
                        </TabPanel>
                    ) : null}
                </TabPanels>
            </Tabs>
        </Box>
    );
};

export default ChatPage;
