import React from 'react';
import { Box, Button, HStack, Link, Text, chakra } from '@chakra-ui/react';
import { Icon } from '../../Icon/Icon';
import { colors } from '../../../theme/colors';

interface UserInformBlockProps {
	isEnabled: boolean;
}

export const UserInformBlock: React.FC<UserInformBlockProps> = ({
	isEnabled,
}) => {
	if (!isEnabled) {
		return null;
	}
	return (
		<Box
			bg="gray.10"
			borderWidth="1px"
			borderColor="gray.20"
			borderRadius="8px"
			overflow="clip"
		>
			<HStack spacing={1} p={2} align="flex-start">
				<Box w="16px" minW="16px" h="16px" py={1}>
					<Icon
						name="info-circle"
						width="16px"
						height="16px"
						color={colors.tertiary}
					/>
				</Box>
				<Text variant="bodySmall" color="secondary">
					<chakra.span fontWeight={500}>
						You help to make DISE happen
					</chakra.span>{' '}
					Please tell two of your BD friends about DISE, it will help
					us gain traction, raise funding, and ship more features
					faster!
				</Text>
			</HStack>
			<Button
				as={Link}
				w="full"
				h="30px"
				bg="transparent"
				borderTopWidth="1px"
				borderTopColor="transparentLight.5"
				iconSpacing={1}
				color="secondary"
				borderRadius="0px"
				fontWeight={500}
				transition="background 0.3s ease"
				href="https://dise.canny.io/feature-requests"
				isExternal={true}
				leftIcon={
					<Icon
						name="mail"
						width="14px"
						height="14px"
						color={colors.secondary}
					/>
				}
				_hover={{
					bg: 'transparentLight.5',
					textDecoration: 'unset',
				}}>
				Request a feature
			</Button>
			<Button
				as={Link}
				w="full"
				h="30px"
				bg="transparent"
				borderTopWidth="1px"
				borderTopColor="transparentLight.5"
				iconSpacing={1}
				color="red.50"
				borderRadius="0px"
				fontWeight={500}
				transition="background 0.3s ease"
				href="https://t.me/valerie_slise"
				isExternal={true}
				leftIcon={
					<Icon
						name="bug"
						width="14px"
						height="14px"
						color={colors.red['50']}
					/>
				}
				_hover={{
					bg: 'transparentLight.5',
					textDecoration: 'unset',
				}}>
				Report a bug (live)
			</Button>
		</Box>
	);
};
