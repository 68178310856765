import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiResponseEmpty } from '../types';
import { QueryKeys } from '../constants';
import API from '../api/api.base';
import { Alerter } from '../utils';
import { resetRecentStack } from '../redux/reducers/search-recent-stack.reducer';
import { RootStore } from '../redux/createStore';
import { useGetMatchedCardId } from '../hooks';
import { resetRequestPromise } from '../hooks/useChats';

export const useSetWorkspace = () => {
    const queryClient = useQueryClient();
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cardId = useGetMatchedCardId();
    const { workSpace } = useSelector((state: RootStore) => state.auth.user);

    return useMutation<ApiResponseEmpty, unknown, number>({
        mutationKey: [QueryKeys.SET_WORKSPACE],
        mutationFn: (payload) =>
            API.put('/api/workspaces/current', { workspaceId: payload }),
        onSuccess(data, variables) {
            if (data.success) {
                dispatch(resetRecentStack());

                if (
                    !pathname.includes('/chat') ||
                    cardId === workSpace?.teamChat?.cardId
                ) {
                    navigate(`/${variables}`, { replace: true });
                    return;
                }

                navigate(pathname);
            } else {
                Alerter.error(
                    data.messages?.[0]?.message ||
                        'Error occurred while selecting workspace',
                );
            }
        },
        onError(error) {
            console.log(error, 'SET_WORKSPACE');
        },
        onSettled() {
            resetRequestPromise();
            queryClient.resetQueries();
        },
    });
};
