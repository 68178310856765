import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponseEmpty } from '../../../../../types';
import API from '../../../../../api/api.base';
import { QueryKeys } from '../../../../../constants';
import { Alerter } from '../../../../../utils';
import { useGetWorkspaceId } from '../../../../../hooks';

export const useUpdateTemplate = () => {
    const workspaceId = useGetWorkspaceId();
    const queryClient = useQueryClient();

    return useMutation<
        ApiResponseEmpty,
        unknown,
        { content: string; templateId: number }
    >({
        mutationKey: [QueryKeys.UPDATE_TEMPLATE],
        mutationFn: (payload) => {
            const { content, templateId } = payload;
            return API.put(
                `/api/workspaces/${workspaceId}/templates/${templateId}/update`,
                { content },
            );
        },
        onSuccess(data) {
            if (data.success) {
                queryClient.invalidateQueries({
                    queryKey: [QueryKeys.GET_TEMPLATES, workspaceId],
                });
                Alerter.success('Template successfully updated');
            } else {
                Alerter.error(
                    data.errors?.[0]?.message ||
                        'Error occurred while updating template',
                );
            }
        },
    });
};
