import { FlagsConfig } from './schema';

export const defaultConfig: FlagsConfig = {
    development: {
        batch_send: false,
        new_auth: false,
    },
    production: {
        batch_send: false,
        new_auth: false,
    },
    testing: {
        batch_send: false,
        new_auth: false,
    },
};
