import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const createWorkspaceSchema = yup.object().shape({
	name: yup.string().required('This field is required'),
	image: yup.string(),
});

export type CreateWorkspaceForm = yup.InferType<typeof createWorkspaceSchema>;

export const createWorkspaceResolver = yupResolver(createWorkspaceSchema);
