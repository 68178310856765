import { Button, Progress } from '@chakra-ui/react';
import { useState } from 'react';
import { useAtomValue } from 'jotai';
import { enteredMessageAtom, selectedCardsAtom } from '../state';
import { useTelegramLock } from '../../../services/telegram/lockContext';
import { parseHtmlAsFormattedText, sleep } from '../../../utils';

import css from './index.module.css';
import classNames from 'classnames';
import { useUnsavedChangesChecker } from '../../../hooks';

export enum ProcessStatusEnum {
	IDLE = 'Waiting',
	SUCCESS = 'Sent',
	ERROR = 'Error',
  NOT_AVAILABLE = 'Not available',
}
interface BatchSendingLaunchProcessProps {
  goToNext: () => void;
  goToPrevious: () => void;
  cancel: () => void;
}
function BatchSendingLaunchProcess({
  goToPrevious,
  cancel,
}: BatchSendingLaunchProcessProps) {
  const lock = useTelegramLock();

  const selectedCards = useAtomValue(selectedCardsAtom);
  const enteredMessage = useAtomValue(enteredMessageAtom);

  const [isProcessLaunched, setIsProcessLaunched] = useState(false);

  useUnsavedChangesChecker(isProcessLaunched);

  const [selectedCardsProcessStatuses, setSelectedCardsProcessStatuses] = useState(
    selectedCards.reduce<Record<string, ProcessStatusEnum>>((acc, item) => {
      acc[item.cardId] = ProcessStatusEnum.IDLE;
      return acc;
    }, {})
  );
  
  const processedCount = Object.values(selectedCardsProcessStatuses).filter((status) => status !== ProcessStatusEnum.IDLE).length;

  const progressPercents = Math.floor((processedCount / selectedCards.length) * 100);

  const handleLaunch = () => {
    lock.scheduleBackgroundEvent(async tg =>
      {
        setIsProcessLaunched(true);

        const { text, entities } =	parseHtmlAsFormattedText(enteredMessage);

        for (const {cardId, chatTelegramId} of selectedCards) {
          try {                        
            const { chatShortInfo } = await tg.custom.proxy.getChatById(chatTelegramId);

            await sleep(300);

            if (!chatShortInfo) {
              console.warn('Batch-send: chat info not found');
              setSelectedCardsProcessStatuses((previousState) => ({ ...previousState, [cardId]: ProcessStatusEnum.NOT_AVAILABLE }));
              continue;
            }
    
            await tg?.methods.proxy.sendMessage({
              chat: chatShortInfo,
              text,
              entities,
              isSilent: true,
            });

            setSelectedCardsProcessStatuses((previousState) => ({ ...previousState, [cardId]: ProcessStatusEnum.SUCCESS }));
          } catch (error) {
            console.error('Batch-send error: ',error);

            setSelectedCardsProcessStatuses((previousState) => ({ ...previousState, [cardId]: ProcessStatusEnum.ERROR }));
          }
        }

        setIsProcessLaunched(false);
      }
    );
  };

  const handleGoToPrevious = () => {
    goToPrevious();
  };

  const handleCancel = () => {
    cancel();
  };

	return (
    <>
      <header className={css.header}>
        <h3 className={css.subtitle}>
          Launch automation
        </h3>

        <p className={css.hint}>
          This process takes time to avoid suspicious activity
          <br />
          You won't be able to use the app while it's running
        </p>
      </header>

      <div className={css.process}>
        <Progress
          className={css.progress}
          value={progressPercents}
          isAnimated={true}
        />

        <div className={css.statistics}>
					<span>
						{progressPercents}% complete
					</span>

					<span color="secondary">
						{processedCount}/{selectedCards.length} chats
					</span>
				</div>
        
        <ul className={css.processItems}>
          {selectedCards.map(({ cardId, cardName }) => {
            const cardProcessStatus = selectedCardsProcessStatuses[cardId];

            return (
              <li 
                key={cardId} 
                className={css.processItem}
              >
                <span className={css.processItemName}>
                  {cardName}
                </span>

                <span 
                  className={
                    classNames(
                      css.processItemStatus,
                      cardProcessStatus === ProcessStatusEnum.IDLE && css.processItemStatusIdle,
                      cardProcessStatus === ProcessStatusEnum.SUCCESS && css.processItemStatusSuccess,
                      cardProcessStatus === ProcessStatusEnum.ERROR && css.processItemStatusError,
                      cardProcessStatus === ProcessStatusEnum.NOT_AVAILABLE && css.processItemStatusNotAvailable,
                    )
                  }
                >
                  {cardProcessStatus}
                </span>
              </li>
            );
          })}
        </ul>
      </div>

      <footer className={css.footer}>
        {processedCount === 0 && (
          <Button
            onClick={handleLaunch}
            isLoading={isProcessLaunched}
          >
            Launch
          </Button>
        )}

        <Button
          variant='outline'
          onClick={handleCancel}
          isDisabled={isProcessLaunched}
        >
          Return to dashboard
        </Button>

        <Button 
          variant='ghost'
          onClick={handleGoToPrevious}
          isDisabled={isProcessLaunched}
        >
          Go back
        </Button>
      </footer>
    </>
  );
}

export default BatchSendingLaunchProcess;
