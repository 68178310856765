import { TreeNodeProps } from 'rc-tree';
import { IconChevronDown, IconChevronRight, IconSquare, IconSquareCheckFilled } from '@tabler/icons-react';


export const switcherIcon = (treeNode: TreeNodeProps) => {
  if (treeNode.isLeaf) return null;

  if (treeNode.expanded) return <IconChevronDown size={'1rem'} />;

  return (
    <IconChevronRight size={'1rem'} />
  );
};

export const Icon = (treeNode: TreeNodeProps) => {
  if (treeNode.checked) return <IconSquareCheckFilled fill='#007AFF' size={'1rem'} />;

  if (treeNode.halfChecked) return <IconSquareCheckFilled fill='#007bff80' size={'1rem'} />;

  return (
    <IconSquare stroke={'1'} size={'1rem'} />
  );
};

