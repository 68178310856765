import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponseEmpty } from '../../../../../types';
import API from '../../../../../api/api.base';
import { QueryKeys } from '../../../../../constants';
import { Alerter } from '../../../../../utils';
import { useGetWorkspaceId } from '../../../../../hooks';

export const useDeleteTemplate = () => {
    const workspaceId = useGetWorkspaceId();
    const queryClient = useQueryClient();

    return useMutation<ApiResponseEmpty, unknown, number>({
        mutationKey: [QueryKeys.DELETE_TEMPLATE],
        mutationFn: (id) =>
            API.delete(`/api/workspaces/${workspaceId}/templates/${id}/delete`),
        onSuccess(data) {
            if (data.success) {
                queryClient.invalidateQueries({
                    queryKey: [QueryKeys.GET_TEMPLATES, workspaceId],
                });
                Alerter.success('Template successfully deleted');
            }
        },
    });
};
