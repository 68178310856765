import { useQuery } from '@tanstack/react-query';
import API from '../api/api.base';
import { ApiResponse, CardStatusItem } from '../types';
import { QueryKeys } from '../constants';
import { useGetMatchedWorkspaceId } from '../hooks';

// CHECK FOR UNUSED
export const useGetWorkspaceCardsStatuses = (isEnabled?: boolean) => {
	const workspaceId = useGetMatchedWorkspaceId();

	return useQuery<ApiResponse<{ statuses: CardStatusItem[] }>>({
		queryKey: [QueryKeys.GET_WORKSPACE_CARDS_STATUSES, workspaceId],
		queryFn: () =>
			API.get(
				`/api/directories/workspaces/${workspaceId}/cards/statuses`,
				{},
			),
		refetchOnWindowFocus: false,
		enabled: !!isEnabled,
	});
};
