/* eslint-disable indent */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { RootStore } from '../../../redux/createStore';
import { useTelegram } from '../../../services';
import {
    ApiUpdateChatInbox,
    ApiUpdateChat,
    ApiMessage,
} from '../../../../../telegram-tt/src/api/types';
import { updateTelegramChatState } from '../../../redux/reducers/telegram-state.reducer';
import { useChats, useLastMessageByChatId } from '../../../hooks/useChats';

const ACTION_USER_LEFT_CHAT = ['Notification.Kicked', 'Notification.LeftChat'];

export const useSubscribeToEvents = () => {
    const tg = useTelegram();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { setMessage } = useLastMessageByChatId();

    const chats = useChats();

    const { telegramUserId: currentUserTelegramId } = useSelector(
        (state: RootStore) => state.auth.user,
    );

    const updateUnread = (data: ApiUpdateChatInbox | ApiUpdateChat) => {
        if (data.id) {
            dispatch(
                updateTelegramChatState({
                    id: +data.id,
                    data: { unreadCount: data.chat.unreadCount || 0 },
                }),
            );
        }
    };

    const updateChatLastMessage = async (newMessage: ApiMessage) => {
        console.log('updateChatLastMessage', newMessage);
        if (!newMessage.chatId) return;

        setMessage(newMessage.chatId, newMessage);
    };

    useEffect(() => {
        const newMessageEvents = tg?.events.subscribe('newMessage', (msg) => {
            const chatToUpdate = msg?.chatId && chats.getChatInfo(msg.chatId);
            // Return if no chat is found with the given chatId
            if (!chatToUpdate) return;

            const isCurrentUserLeftChat =
                msg.content?.action?.targetUserIds?.includes(
                    currentUserTelegramId.toString(),
                ) &&
                ACTION_USER_LEFT_CHAT.includes(msg.content?.action?.text || '');

            if (isCurrentUserLeftChat) return;

            updateChatLastMessage(msg as ApiMessage);
        });

        const chatInboxEvents = tg?.events.subscribe(
            'updateChatInbox',
            (chatData) => {
                // console.log('---- NEW CHAT_INBOX UPDATE ----', chatData);

                const chatToUpdate =
                    chatData?.id && chats.getChatInfo(chatData.id);

                if (!chatToUpdate) return;

                updateUnread(chatData);
            },
        );

        const updateChatEvents = tg?.events.subscribe(
            'updateChat',
            (chatData) => {
                console.log('---- NEW CHAT UPDATE ----', chatData);

                const chatToUpdate =
                    chatData?.id && chats.getChatInfo(chatData.id);
                if (!chatToUpdate) return;

                const unreadCountKey = 'unreadCount' in chatData.chat;
                if (!unreadCountKey) return;

                updateUnread(chatData);
            },
        );

        const updateChatMembersEvents = tg?.events.subscribe(
            'updateChatMembers',
            (data) => {
                const chatToUpdate = data?.id && chats.getChatInfo(data.id);

                const isChatMembersUpdateType =
                    data['@type'] === 'updateChatMembers';

                if (!chatToUpdate || !data?.id || !isChatMembersUpdateType) {
                    return;
                }

                queryClient.refetchQueries({
                    queryKey: [`tg1-chat-${+data.id}`],
                });
            },
        );

        return () => {
            newMessageEvents?.();
            chatInboxEvents?.();
            updateChatEvents?.();
            updateChatMembersEvents?.();
        };
    }, [tg?.events, chats]);
};
