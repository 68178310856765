import { memo, PropsWithChildren, useEffect } from 'react';
import { useNavigate, useParams } from '@router';
import { useGetCurrentUserQuery } from '@core/api';
import { Spinner } from '@chakra-ui/react';

function Redirects({ children }: PropsWithChildren) {
	const navigate = useNavigate();

	const { workspaceId } = useParams('/:workspaceId');
	
	const getCurrentUserQuery = useGetCurrentUserQuery();

	useEffect(() => {
		if (getCurrentUserQuery.isSuccess) {
			if (!getCurrentUserQuery.data?.isOnboarded) {
				console.log('Redirects: to onboarding modal');
		
				navigate('/onboarding');
				return;
			}
	
			if (!workspaceId && getCurrentUserQuery.data.workSpace?.id) {
				console.log('Redirects: to current workspace');
	
				navigate('/:workspaceId', { params: { workspaceId: String(getCurrentUserQuery.data.workSpace?.id) } });
				return;
			}
		}
	}, [getCurrentUserQuery.isSuccess, getCurrentUserQuery.data?.isOnboarded, workspaceId])

	if (getCurrentUserQuery.isLoading) return <Spinner size='xl' />

	return children;
}

export default memo(Redirects);
