import React from 'react';
import { MenuItem, Text } from '@chakra-ui/react';
import { WorkspaceItemImage } from './WorkspaceItemImage';
import { useSetWorkspace } from '../../../../queries';

interface IWorkspaceSelectItemProps {
	id: number;
	name: string;
	imageLink?: string;
}

export const WorkspaceSelectItem: React.FC<IWorkspaceSelectItemProps> = ({
	id,
	name,
	imageLink,
}) => {
	const setWorkspaceMutation = useSetWorkspace();

	return (
		<MenuItem
			id='select-workspace-item'
			data-testid={'select-workspace-item'}
			height="40px"
			borderBottom="1px"
			borderColor="gray.15"
			gap="8px"
			px="8px"
			onClick={() => setWorkspaceMutation.mutate(id)}
			_hover={{ bg: 'transparentLight.10' }}
			_focus={{ bg: 'transparentLight.10' }}
		>
			<WorkspaceItemImage imageLink={imageLink} />

			<Text
				fontSize="13px"
				color="secondary"
				fontWeight={400}
				lineHeight="19.5px"
				noOfLines={1}>
				{name}
			</Text>
		</MenuItem>
	);
};
