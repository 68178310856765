import { BoardCardItemResponse } from '../features/Dashboard/types';
import { useChats } from '../hooks/useChats';

export function appendLastMessageDataToCardForSearch(
    cards: BoardCardItemResponse[],
    chats: ReturnType<typeof useChats>,
) {
    return cards.map((elem) => ({
        ...elem,
        lastMessage: chats.data?.lastMessages?.[elem.chatTelegramId],
    }));
}
