import { MainLayout } from '@components/MainLayout/MainLayout';

export const Catch = () => {
	return <div>Something went wrong... Caught at _app error boundary</div>;
};

export const Pending = () => <div>Loading from _app...</div>;

export default function Layout() {
	return (
		<MainLayout />
	);
}
