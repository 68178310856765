import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import API from '../api/api.base';
import { ApiResponseEmpty } from '../types';
import { logout } from '../redux/reducers/auth.reducer';
import { setTelegramConnected } from '../redux/reducers/login-flow.reducer';
import { QueryKeys } from '../constants';
import { clearTgDataCache } from '../redux/reducers/telegram-state.reducer';
import { setOnboardingVisibility } from '../redux/reducers/onboarding.reducer';
import { useGetMatchedWorkspaceId } from '../hooks/useGetWorkspaceId';
import { invoke, SignalRMethods, useTelegram } from '../services';
import { RootStore } from '../redux/createStore';
import { useNavigate } from '@router';
import { clear } from 'idb-keyval';
import { resetRequestPromise } from '../hooks/useChats';

export const useLogout = () => {
    const tg = useTelegram();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const workspaceId = useGetMatchedWorkspaceId();
    const { signalRConnected } = useSelector(
        (state: RootStore) => state.signalR,
    );
    const queryClient = useQueryClient();

    return useMutation<
        ApiResponseEmpty,
        unknown,
        { accessToken: string; refreshToken: string }
    >({
        mutationKey: [QueryKeys.LOGOUT],
        mutationFn: (payload) => API.post('/api/auth/logout', payload),
        onSettled: async () => {
            if (workspaceId && signalRConnected) {
                await invoke(SignalRMethods.WorkspaceLeaveChat, workspaceId);
            }
            dispatch(logout());
            dispatch(setTelegramConnected({ isConnected: false }));
            dispatch(clearTgDataCache());
            dispatch(setOnboardingVisibility(false));

            await tg?.actions.proxy.signOut({
                forceInitApi: true,
            });

            await queryClient.cancelQueries();
            await queryClient.clear();
            resetRequestPromise();
            await queryClient.resetQueries();
            localStorage.clear();
            await clear();

            setTimeout(() => {
                window.location.reload();
            }, 1000);

            navigate('/login');
        },
    });
};
