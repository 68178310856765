import MembersSettingsPage from '@features/Settings/subpages/Members.settings.page';

export const Loader = () => 'Route loader';

export const Action = () => 'Route action';

export const Catch = () => <div>Something went wrong...</div>;

export const Pending = () => <div>Loading...</div>;

export default function SettingsMembersPage() {
	return <MembersSettingsPage />;
}
