import { Button } from '@chakra-ui/react';
import css from './index.module.css';
import { BtnBold, BtnItalic, BtnLink, Editor, EditorProvider } from '../../../components';
import { useState } from 'react';
import { useAtom } from 'jotai';
import { enteredMessageAtom } from '../state';

interface BatchSendingEnterMessageProps {
  goToNext: () => void;
  goToPrevious: () => void;
  cancel: () => void;
}
function BatchSendingEnterMessage({
  goToNext, 
  goToPrevious,
}: BatchSendingEnterMessageProps) {
  const [enteredMessage, setEnteredMessage] = useAtom(enteredMessageAtom);

  const [message, setMessage] = useState<string>(enteredMessage);

  const handleChangeMessage = (text: string) => {
    setMessage(text);
  };

  // const handleSendTestMessage = () => {
  //   lock.scheduleBackgroundEvent(async tg =>
  //     {
  //       const { chatShortInfo } = await tg.custom.proxy.getChatById(
	// 				-1002290344910
	// 			);

  //       if (!chatShortInfo) return;

  //       for (let i = 0; i < 100; i++) {
  //         tg?.methods.proxy.sendMessage({
  //           chat: chatShortInfo,
  //           text: `LOL from CRM => ${i}`,
  //           isSilent: true,
  //         });
  //       }
  //     }
  //   );
  // };

  const handleGoToNext = () => {
    if (!message.length) return;

    setEnteredMessage(message);
    
    goToNext();
  };

  const handleGoToPrevious = () => {
    goToPrevious();
  };

	return (
    <>
      <header className={css.header}>
        <h3 className={css.subtitle}>
          Prepare the message
        </h3>

        <p className={css.hint}>
          You can format it here or copy from Telegram
        </p>
      </header>

      <div className={css.editorHolder}>
        <EditorProvider>
          <Editor
            value={message}
            className={css.editor}
            onChange={(e) => handleChangeMessage(e.target.value)}
          />

          <div className={css.editorToolbar}>
            <BtnBold />
            <BtnItalic />
            <BtnLink />

            {/* <Button
              variant='link'
              size='xs'
              isDisabled={!message.length}
              title={!message.length ? 'You must enter message to test' : ''}
              className={css.testButton}
              onClick={handleSendTestMessage}
            >
              Send test message to yourself
            </Button> */}
          </div>
        </EditorProvider>
      </div>

      <footer className={css.footer}>
        <Button
          variant='outline'
          isDisabled={!message.length}
          title={!message.length ? 'You must enter message to continue' : 'Continue to next step'}
          className={css.continueButton}
          onClick={handleGoToNext}
        >
          Continue
        </Button>

        <Button 
          variant='ghost' 
          onClick={handleGoToPrevious}
        >
          Go back
        </Button>
      </footer>
    </>
  );
}

export default BatchSendingEnterMessage;
