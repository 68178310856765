import { Progress, Text } from '@chakra-ui/react';
import { atom, useAtom } from 'jotai';
import { Icon } from '../../../components/Icon/Icon';

import css from './index.module.css';

const loadingChatsAtom = atom<Set<number>>(new Set<number>());
const totalChatsAtom = atom<number>(0);

export const useLoadingProgress = () => {
	const [loadingChats, setLoadingChats] = useAtom(loadingChatsAtom);
	const [totalChats, setTotalChats] = useAtom(totalChatsAtom);

	return {
		loadingChats,
		totalChats,
		addLoadingChat: (chatId: number) =>
			setLoadingChats(prev => new Set(prev).add(chatId)),
		removeLoadingChat: (chatId: number) =>
			setLoadingChats(prev => {
				const newSet = new Set(prev);
				newSet.delete(chatId);
				return newSet;
			}),
		resetState: () => {
			setLoadingChats(new Set());
			setTotalChats(0);
		},
		setTotal: (total: number) => setTotalChats(total),
		loadedCount: totalChats - loadingChats.size,
	};
};

export const LoadingOverlay = () => {
	const { loadingChats, totalChats, loadedCount } = useLoadingProgress();

	if (totalChats === 0) return null;

	if (loadedCount === totalChats && loadingChats.size === 0) return null;

	const progress = Math.max(
		0,
		totalChats ? Math.floor((loadedCount / totalChats) * 100) : 0,
	);

	return (
		<section id="overlay-modal" className={css.overlay}>
			<div className={css.modal}>
				<Text
					as={'h2'}
					fontSize="xl"
					fontWeight="600"
					mb={'1rem'}
				>
					Preparing your Workspace
				</Text>

				<Text
					as={'span'}
					fontSize="sm"
					color="gray.600"
					mb={'2rem'}
				>
					Syncing chats with Telegram...
				</Text>

				<Progress
					className={css.progress}
					value={progress}
					isAnimated={true}
				/>

				<div className={css.statistics}>
					<Text fontSize="sm">
						{Math.max(0, progress)}% complete
					</Text>
					<Text fontSize="sm" color="secondary">
						{Math.max(0, loadedCount)}/{totalChats} chats
					</Text>
				</div>

				<div className={css.alert}>
					<Icon
						name="info-circle"
						width="1rem"
						height="1rem"
						color={'#666666'}
					/>

					<Text fontSize="0.75rem" color="#666666">
						Please don't close this tab.
					</Text>
				</div>

				<Text fontSize="0.75rem" color="#999999">
					This may take a few moments...
				</Text>
			</div>
		</section>
	);
};
