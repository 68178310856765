import { Box, Button, Flex, HStack, Icon, Text } from "@chakra-ui/react";
import { InputText, TeamMember } from "@components/index";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useGetTeammateByUsername } from "../../../hooks";
import { Alerter } from "../../../utils";
import { useInviteMembersToWorkspaceMutation } from "@core/api";
import { useAtomValue } from "jotai";
import { createdWorkspaceIdAtom } from "../state";

type HandleForm = {
	username: string;
};

export type UserInvitePayload = {
	telegramUserId: number;
	firstName: string;
	lastName: string | null;
};

interface OnboardingInviteTeamProps {
  goToNext: () => void;
  goToPrevious: () => void;
  cancel: () => void;
}
function OnboardingInviteTeam({goToNext}: OnboardingInviteTeamProps) {
	const createdWorkspaceId = useAtomValue(createdWorkspaceIdAtom);

	const inviteMembersToWorkspaceMutation = useInviteMembersToWorkspaceMutation(createdWorkspaceId!);

	const [selectedTeammates, setSelectedTeammates] = useState<
		{
			id?: string;
			firstName?: string;
			lastName?: string;
			username: string;
			avatar?: string;
		}[]
	>([]);

	const { register, handleSubmit, reset } = useForm<HandleForm>();

  	const { onAddHandle, isLoading: isUserFetchLoading } = useGetTeammateByUsername(value => {
		const matchedTeammate = selectedTeammates?.find(
			user => user.id === value.id,
		);
		if (matchedTeammate) {
			Alerter.warning(
			`User ${value.firstName}${value.lastName ? ` ${value.lastName}` : ''} is already on the list`,
			);
			reset({ username: '' });
			return;
		}
		setSelectedTeammates(prev => [...prev, value]);
		reset({ username: '' });
    });

	const handleInviteTeam = async () => {
		try {
		const payload = selectedTeammates.map(elem => {
				if (elem.id) {
					const payload: UserInvitePayload = {
						telegramUserId: +elem.id,
						firstName: elem.firstName || 'unknown',
						lastName: elem.lastName || null,
					};

					return payload;
				}
				return null;
			})
			.filter(elem => elem !== null) as UserInvitePayload[];
			
			await inviteMembersToWorkspaceMutation.mutateAsync({
				telegramUsers: payload
			});

			goToNext();
		} catch (error) {
		console.log(error);
		}
	};

	const handleSkip = () => {
		goToNext();
	}

	const onAdd = async (data: HandleForm) => {
		onAddHandle(data.username);
	};


	const onDeleteMember = (id?: string) => {
		setSelectedTeammates(prev => prev.filter(elem => elem.id !== id));
	};

	return (
    	<Flex flexDirection={'column'} align={'center'} gap={'30px'}>
			<Box>
				<Text
					fontWeight={'600'}
					fontSize={'20px'}
					lineHeight={'24px'}
					textAlign={'center'}>
					Invite your team
				</Text>

				<Text textAlign={'center'} mt={'16px'} color={'secondary'}>
					Get the most out of your workspace by inviting your team.
					Use Telegram usernames to add people.
				</Text>
			</Box>

			<Box w={'full'} position="relative">
				<form onSubmit={handleSubmit(onAdd)}>
					<HStack align="flex-end">
						<InputText {...register('username')} label="USERNAME" />
						<Button
							variant="outline"
							type="submit"
							isLoading={isUserFetchLoading}
							borderRadius="4px"
							background="gray.10"
							color="secondary"
							leftIcon={
								<Icon name="plus" width="14px" height="14px" />
							}>
							Add
						</Button>
					</HStack>
				</form>

				{selectedTeammates.length ? (
					<Box
						borderRadius="8px"
						bg="gray.10"
						border="1px solid"
						mt="32px"
						maxH="500px"
						overflowY="auto"
						borderColor="gray.20"
					>
						{selectedTeammates.map(elem => (
							<TeamMember
								key={elem.id}
								username={elem.username}
								name={
									elem.firstName + ' ' + (elem.lastName || '')
								}
								avatar={elem.avatar}
								onDelete={() => onDeleteMember(elem.id)}
								includeDelete={true}
							/>
						))}
					</Box>
				) : null}
			</Box>

			<Button
				onClick={handleInviteTeam}
				isLoading={inviteMembersToWorkspaceMutation.isPending}
				isDisabled={!selectedTeammates.length}
			>
				Continue
			</Button>

			<Button
				variant={'ghost'}
				onClick={handleSkip}
			>
				Skip
			</Button>
		</Flex>
  );
}

export default OnboardingInviteTeam;
