import { ApiChat, ApiMessage } from '../../../telegram-tt/src/api/types';
import { BoardCardItemResponse } from '../features/Dashboard/types';
import { TgDataForCard } from '../types';

export const sortCardsByDateAndAccess = (
    cardA: BoardCardItemResponse,
    cardB: BoardCardItemResponse,
    chats: Record<string, ApiChat>,
    messages: Record<string, ApiMessage>,
    lastMessages?: Record<string, number>,
) => {
    const tgChatA = chats?.[cardA.chatTelegramId];
    const tgChatB = chats?.[cardB.chatTelegramId];

    if (!tgChatA) {
        return 1;
    }
    if (!tgChatB) {
        return -1;
    }

    const lastMessageIndexA = lastMessages?.[tgChatA.id];
    const lastMessageIndexB = lastMessages?.[tgChatB.id];

    if (!lastMessageIndexA || !lastMessageIndexB) {
        return 0;
    }

    const tgChatALastMessageDate = messages?.[lastMessageIndexA]?.date || 0;
    const tgChatBLastMessageDate = messages?.[lastMessageIndexB]?.date || 0;

    return tgChatBLastMessageDate - tgChatALastMessageDate;
};
