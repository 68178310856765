import { ApiUser } from '../../../telegram-tt/src/api/types';
import { colors } from '../theme/colors';
import {
    CardTaskPriorityEnum,
    BaseUserData,
    CardDefaultStatusEnum,
} from '../types';

export const getTaskStatusByType = ({
    position,
    typeId,
}: {
    position: number;
    typeId: number;
}) => {
    if (typeId === CardDefaultStatusEnum.ARCHIVE) {
        return {
            textColor: 'gray.60',
            bgColor: 'gray.20',
        };
    }

    switch (position) {
        case 1:
            return {
                textColor: 'turquoise.60',
                bgColor: 'turquoise.20',
            };
        case 2:
            return {
                textColor: 'sky.60',
                bgColor: 'sky.20',
            };
        case 3:
            return {
                textColor: 'purple.60',
                bgColor: 'purple.20',
            };
        case 4:
            return {
                title: 'Ready to start',
                textColor: 'pink.60',
                bgColor: 'pink.20',
            };
        case 5:
            return {
                textColor: 'red.60',
                bgColor: 'red.20',
            };
        case 6:
            return {
                textColor: 'orange.60',
                bgColor: 'orange.20',
            };
        case 7:
            return {
                textColor: 'yellow.60',
                bgColor: 'yellow.20',
            };
        case 8:
            return {
                textColor: 'green.60',
                bgColor: 'green.20',
            };
        case 9:
            return {
                textColor: 'blue.60',
                bgColor: 'blue.20',
            };
        case 10:
            return {
                textColor: 'purple.60',
                bgColor: 'purple.20',
            };
        default:
            return {
                textColor: 'gray.60',
                bgColor: 'gray.20',
            };
    }
};

export const getPriorityStatusById = (type: CardTaskPriorityEnum) => {
    switch (type) {
        case CardTaskPriorityEnum.LOW:
            return {
                title: 'Low',
                textColor: 'yellow.60',
                bgColor: 'yellow.20',
            };
        case CardTaskPriorityEnum.MEDIUM:
            return {
                title: 'Medium',
                textColor: 'orange.60',
                bgColor: 'orange.20',
            };
        case CardTaskPriorityEnum.HIGH:
            return {
                title: 'High',
                textColor: 'red.60',
                bgColor: 'red.20',
            };
        default:
            return {
                title: 'Unknown',
                textColor: 'gray.60',
                bgColor: 'gray.20',
            };
    }
};

export const getActivityIconByTypeId = (typeId: number) => {
    switch (typeId) {
        case 1:
            return 'video';
        case 2:
            return 'phone';
        case 3:
            return 'video-off';
        case 4:
            return 'note';
        case 5:
        case 6:
            return 'checkbox';
        default:
            return '';
    }
};

export const getLabelInfoByType = (typeId: number) => {
    switch (typeId) {
        case 1:
            return {
                textColor: colors.green[60],
                bgColor: colors.green[20],
            };
        case 2:
            return {
                textColor: colors.turquoise[60],
                bgColor: colors.turquoise[20],
            };
        case 3:
            return {
                textColor: colors.sky[60],
                bgColor: colors.sky[20],
            };
        case 4:
            return {
                textColor: colors.blue[60],
                bgColor: colors.blue[20],
            };
        case 5:
            return {
                textColor: colors.purple[60],
                bgColor: colors.purple[20],
            };
        case 6:
            return {
                textColor: colors.pink[60],
                bgColor: colors.pink[20],
            };

        default:
            return {
                textColor: colors.gray[60],
                bgColor: colors.gray[20],
            };
    }
};

export const getActivitySubjectName = (
    subjectInfo: { firstName?: string; lastName?: string } | null,
) =>
    [subjectInfo?.firstName, subjectInfo?.lastName].filter(Boolean).join(' ') ||
    '';
