import { useQuery } from '@tanstack/react-query';
import API from '../api/api.base';
import { QueryKeys } from '../constants';
import { ApiResponse, WorkspaceSelectItem } from '../types';

export const useGetWorkspaces = () => {
	return useQuery<ApiResponse<WorkspaceSelectItem[] | null>>({
		queryKey: [QueryKeys.GET_WORKSPACES],
		queryFn: () => API.get('/api/workspaces', {}),
		refetchOnWindowFocus: false,
	});
};
