import { useQuery } from '@tanstack/react-query';
import { LegacyApiResponse } from '../axios';
import { queryClientForApi } from '../query-client';
import axios from 'axios';

export type GetCurrentUserResult = {
	avatarThumbnailLink: string | null;
	avatarUrl: string | null;
	firstName: string | null;
	id: number;
	telegramUserId: number;
	lastName: string | null;
	isOnboarded: boolean;
	workSpace: {
		id: number;
		name: string;
		avatarUrl: string | null;
		avatarThumbnailUrl: string | null;
		teamChat: {
			id: number;
			name: string;
			cardId: number;
		} | null;
		isArchiveVisible: boolean;
		isAdmin: boolean;
	} | null;
	timeZoneId: string | null;
};

const getCurrentUser = async () => {
	const { data } = await axios.get<LegacyApiResponse<GetCurrentUserResult>>(
		'/api/users/current',
	);	

	return data.value;
};

const QUERY_KEY_GET_CURRENT_USER = 'QUERY_KEY_GET_CURRENT_USER';

function useGetCurrentUserQuery() {	
	return useQuery<GetCurrentUserResult>(
		{
			queryKey: [QUERY_KEY_GET_CURRENT_USER],
			queryFn: getCurrentUser,
		},
		queryClientForApi
	);
}

export { getCurrentUser, useGetCurrentUserQuery, QUERY_KEY_GET_CURRENT_USER };
