import { MouseEvent, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flex, VStack, Box, Text } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getAutomationNavLinkItems, getNavLinkItems } from '../constants';
import { SidebarNavItem } from './SidebarNavItem';
import { SidebarCompanyHeader } from './SidebarCompanyHeader';
import { LogoutButton } from './LogoutButton';
import { TaskCardSolo } from '../../TaskCard/TaskCardSolo';
import { TeamChatPlaceholder } from '../../TaskCard/TeamChatPlaceholder';
import { SidebarChatView } from './SidebarChatView';
import { SidebarSettingsView } from './SidebarSettingsView';
import {
    ApiResponse,
    NotificationsCountUpdatePayload,
} from '../../../types';
import {
    useGetNotificationsCount,
    useGetUserTasksCount,
} from '../../../queries';
import { QueryKeys } from '../../../constants';
import {
    invoke,
    SignalRMethods,
    SignalRTopics,
    useSignalREffect,
} from '../../../services';
import { RootStore } from '../../../redux/createStore';
import { useGetMatchedCardId, useGetMatchedWorkspaceId } from '../../../hooks';
import { UserInformBlock } from './UserInformBlock';
import { useChats } from '../../../hooks/useChats';
import { useModals } from '@router';

export const SidebarContent = () => {
    const { workSpace } = useSelector((state: RootStore) => state.auth.user);

    const modals = useModals();
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();
    const workspaceId = useGetMatchedWorkspaceId();
    const cardId = useGetMatchedCardId();
    const chats = useChats();
    const { signalRConnected } = useSelector(
        (state: RootStore) => state.signalR,
    );

    const teamTgIds =
        queryClient.getQueryData<ApiResponse<{ telegramUserIds: number[] }>>([
            QueryKeys.GET_WORKSPACE_TELEGRAM_IDS,
            workspaceId,
        ])?.value?.telegramUserIds || [];

    const { isDashboard, isTasks } = useMemo(() => {
        const dashboardRouteRegex = /^\/\d+$/;
        const tasksRouteRegex = /^\/\d+\/tasks$/;
        const isDashboard = dashboardRouteRegex.test(location.pathname);
        const isTasks = tasksRouteRegex.test(location.pathname);

        return { isDashboard, isTasks };
    }, [location.pathname]);

    const { data: tasksCountData } = useGetUserTasksCount(
        isDashboard || isTasks,
    );

    const { data: notificationsCountData } = useGetNotificationsCount();

    const showSidebarSettingsView = location.pathname.includes('settings');

    const showSidebarChatView =
        workSpace?.teamChat?.cardId !== cardId &&
        location.pathname.includes('chat');

    const onRedirect = (chatTelegramId?: number, cardId?: number) => {
        if (!chatTelegramId) {
            return;
        }

        navigate(`/${workspaceId}/chat/${cardId}`, {
            state: {
                chatTelegramId,
                isTeamChat: true,
                id: cardId,
            },
        });
    };

    useEffect(() => {
        if (workspaceId && (isTasks || isDashboard)) {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.GET_USER_TASKS_COUNT, workspaceId],
            });
        }
    }, [isTasks, isDashboard, workspaceId, queryClient]);

    useSignalREffect(
        SignalRTopics.NotificationCountUpdate,
        (data: NotificationsCountUpdatePayload) => {
            if (!workspaceId) {
                return;
            }
            const { isAdded } = data;

            queryClient.setQueryData<ApiResponse<{ count: number }>>(
                [QueryKeys.GET_NOTIFICATIONS_COUNT, workspaceId],
                (oldData) => {
                    if (oldData) {
                        return {
                            ...oldData,
                            value: {
                                count: isAdded
                                    ? oldData.value.count + 1
                                    : Math.max(0, oldData.value.count - 1),
                            },
                        };
                    }
                    return oldData;
                },
            );
        },
        [workspaceId],
    );

    useEffect(() => {
        if (signalRConnected && workspaceId) {
            invoke(SignalRMethods.WorkspaceJoinChat, workspaceId);
        }

        return () => {
            if (workspaceId && signalRConnected) {
                invoke(SignalRMethods.WorkspaceLeaveChat, workspaceId);
            }
        };
    }, [signalRConnected, workspaceId]);

    const getActivityCount = useCallback(
        (id: number) => {
            switch (id) {
                case 2:
                    return tasksCountData?.value || 0;
                case 3:
                    return notificationsCountData?.value?.count || 0;
                default:
                    return 0;
            }
        },
        [tasksCountData?.value, notificationsCountData?.value],
    );

    const handleOpenBatchSending = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        modals.open('/batch-sending', {
            at: '/:workspaceId',
            params: { workspaceId: `${workspaceId}` },
        });
    };    

    return (
        <>
            <Flex
                as="aside"
                direction="column"
                alignItems="space-between"
                h="100vh"
                p="0.75rem 0.5rem"
                w="240px"
                flexShrink={0}
                overflowY="auto"
                sx={{
                    '&::-webkit-scrollbar': {
                        width: 0,
                    },
                    '&::-webkit-scrollbar-track': {
                        width: 0,
                    },
                    '&::-webkit-scrollbar-thumb': {
                        width: 0,
                    },
                }}
            >
                {workSpace ? (
                    <SidebarCompanyHeader
                        wrapperProps={{
                            display: {
                                base: 'none',
                                md: !showSidebarSettingsView ? 'flex' : 'none',
                            },
                        }}
                        showSidebarChatView={showSidebarChatView}
                    />
                ) : null}

                <Flex
                    direction="column"
                    alignItems="stretch"
                    position="relative"
                    id='kek'
                    display={{ base: 'flex', md: 'none' }}
                >
                    <Flex
                        justifyContent="center"
                        alignItems="center"
                        mx={{ base: 0, md: 4 }}
                    >
                        {workSpace ? (
                            <SidebarCompanyHeader
                                showSidebarChatView={showSidebarChatView}
                            />
                        ) : null}
                    </Flex>
                </Flex>

                {showSidebarChatView ? null : !showSidebarSettingsView ? (
                    <VStack spacing={'2px'} my={'2rem'} w={'100%'}>
                        {getNavLinkItems(workspaceId)
                            .filter((elem) =>
                                // exclude settings for non admin users
                                workSpace?.isAdmin ? true : elem.id !== 5,
                            )
                            .map((elem) => (
                                <SidebarNavItem
                                    key={elem.id}
                                    name={elem.name}
                                    path={elem.path}
                                    iconName={elem.iconName}
                                    location={location.pathname}
                                    isSoon={elem.isSoon}
                                    isActive={elem.path === location.pathname}
                                    activity={getActivityCount(elem.id)}
                                />
                            ))}

                        <LogoutButton />
                    </VStack>
                ) : null}

                {showSidebarChatView ? null : !showSidebarSettingsView ? (
                    <>
                        <Text
                            fontSize={'0.75rem'}
                            mb={'0.5rem'}
                            color={'#B3B3B3'}
                            fontWeight={600}
                            textTransform={'uppercase'}
                        >
                            Automation
                        </Text>

                        <VStack spacing={'2px'} w={'100%'} mb={'2rem'}>
                            <button
                                onClick={handleOpenBatchSending}
                                style={{ width: '100%' }}
                            >
                                <SidebarNavItem
                                    name={'Batch Send'}
                                    path={`/${workspaceId}`}
                                    iconName={'mail-share'}
                                    location={location.pathname}
                                    isSoon={false}
                                    isActive={false}
                                />
                            </button>

                            {getAutomationNavLinkItems(workspaceId).map(
                                (elem) => (
                                    <SidebarNavItem
                                        key={elem.id}
                                        name={elem.name}
                                        path={elem.path}
                                        iconName={elem.iconName}
                                        location={location.pathname}
                                        isSoon={elem.isSoon}
                                        isActive={false}
                                    />
                                ),
                            )}
                        </VStack>
                    </>
                ) : null}

                <UserInformBlock isEnabled={isDashboard} />

                {showSidebarChatView ? (
                    <SidebarChatView teamTgIds={teamTgIds} />
                ) : null}

                {showSidebarSettingsView ? <SidebarSettingsView /> : null}

                {!showSidebarSettingsView ? (
                    <Box
                        mt="auto"
                        w="100%"
                        display={{ base: 'none', md: 'block' }}
                    >
                        {workSpace?.teamChat ? (
                            <TaskCardSolo
                                chats={chats}
                                chat={chats.getChatInfo(
                                    workSpace?.teamChat?.id.toString(),
                                )}
                                isDragging={false}
                                cardName={workSpace?.teamChat?.name || ''}
                                chatTelegramId={workSpace?.teamChat?.id || 0}
                                onRedirect={() =>
                                    onRedirect(
                                        workSpace?.teamChat?.id,
                                        workSpace?.teamChat?.cardId,
                                    )
                                }
                                isTeamChat={true}
                                cardId={workSpace.teamChat.cardId}
                                teamTgIds={teamTgIds}
                            />
                        ) : (
                            <TeamChatPlaceholder
                                teamChatData={workSpace?.teamChat}
                                isAdmin={workSpace?.isAdmin}
                            />
                        )}
                    </Box>
                ) : null}
            </Flex>
        </>
    );
};
