import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { LegacyApiResponse } from '../axios';

const createWorkspace = async (payload: FormData) => {
	const { data } = await axios.post<LegacyApiResponse<number>>(
		'/api/workspaces',
		payload,
	);

	return data.value;
};

const MUTATION_KEY_CREATE_WORSPACE = 'MUTATION_KEY_CREATE_WORSPACE';

function useCreateWorkspaceMutation() {
	return useMutation<number, unknown, FormData>({
		mutationKey: [MUTATION_KEY_CREATE_WORSPACE],
		mutationFn: createWorkspace,
	});
}

export { createWorkspace, useCreateWorkspaceMutation, MUTATION_KEY_CREATE_WORSPACE };
