import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Text, HStack } from '@chakra-ui/react';
import { Icon } from '../../Icon/Icon';
import { colors } from '../../../theme/colors';
import { EventsIndicator } from '../../EventsIndicator/EventsIndicator';

interface SidebarNavItemProps {
	name: string;
	iconName: string;
	path: string;
	location: string;
	isSoon: boolean;
	isActive: boolean;
	activity?: number;
}

export const SidebarNavItem: React.FC<SidebarNavItemProps> = ({
	isSoon,
	path,
	isActive,
	name,
	activity,
	iconName,
}) => {
	return (
		<Box
			w="full"
			p={1}
			borderRadius="4px"
			bg={isActive ? 'transparentLight.5' : 'transparent'}
			transition="background 0.3s ease"
			_hover={{
				bg: isSoon
					? 'transparent'
					: isActive
						? 'transparentLight.5'
						: 'transparentLight.10',
			}}
		>
			<Link
				width="100%"
				as={RouterLink}
				transition=".3s"
				to={isSoon ? location : path}
				cursor={isSoon ? 'default' : 'pointer'}
				_hover={{
					textDecoration: 'none',
				}}
			>
				<HStack align="center" justify="space-between">
					<HStack align="center" spacing={2}>
						<Icon
							name={iconName}
							color={isActive ? colors.primary : colors.secondary}
							width="16px"
							height="16px"
						/>
						<Text
							lineHeight="16px"
							color={isActive ? 'primary' : 'secondary'}>
							{name}
						</Text>
					</HStack>
					{isSoon ? (
						<Box px={2} bg="transparentLight.5" borderRadius="50px">
							<Text
								variant="bodySmall"
								lineHeight="16px"
								color="gray.35">
								Soon
							</Text>
						</Box>
					) : null}
					{activity ? (
						<EventsIndicator
							count={activity}
							wrapperProps={{
								bg: 'blue.DEFAULT',
							}}
							textProps={{
								color: 'white',
							}}
						/>
					) : null}
				</HStack>
			</Link>
		</Box>
	);
};
