import { Outlet, useLocation } from 'react-router-dom';
import AuthRedirects from './_auth-redirects';
import { Sidebar } from '../components/MainLayout/components';

import {
    CreateWorkspaceModal,
    InviteMembersModal,
    OnboardingModal,
    SelectTeamChatModal,
    SyncSettingsModal,
} from '../modals';
import { ChatPageHeader } from '../widgets';
import { TelegramHiddenFrame } from '../components';
import css from './_app.module.css';
import { useParams } from '@router';

export const Catch = () => {
    return <div>Something went wrong... Caught at _app error boundary</div>;
};

export const Pending = () => <div>Loading from _app...</div>;

export default function App() {
    const { pathname } = useLocation();

    const { workspaceId } = useParams('/:workspaceId');

    const isChat = pathname.includes('/chat');
    const isOnboarding = pathname.includes('/onboarding');
    const isSelectWorkspace = pathname.includes('/select-workspace');
    const isLogin = pathname.includes('/login');

    return (
        <>
            <section className={css.layout}>
                {workspaceId && !isLogin && !isOnboarding && !isSelectWorkspace ? (
                    <Sidebar />
                ) : null}

                <main className={css.main}>
                    {isChat ? <ChatPageHeader /> : null}

                    <section className={css.sublayout}>
                        <TelegramHiddenFrame />

                        <AuthRedirects>
                            <Outlet />
                        </AuthRedirects>
                    </section>
                </main>
            </section>

            <OnboardingModal />

            <InviteMembersModal />

            <SyncSettingsModal />

            <CreateWorkspaceModal />

            <SelectTeamChatModal />
        </>
    );
}
