import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useInterval } from 'usehooks-ts';
import { TG_CONFIG } from '../../tg-config';
import { useTelegram } from '../../services';
import {
    useThrottleCallback,
    useGetMatchedCardId,
    useGetCardById,
} from '../../hooks';
import { useLogout } from '../../queries';
import { RootStore } from '../../redux/createStore';
import {
    CardDefaultStatusEnum,
    TGStateAuthenticatedUser,
    TGStateUnauthenticatedUser,
    TGSyncState,
} from '../../types';
import {
    setShowTelegramModal,
    setTelegramConnected,
} from '../../redux/reducers/login-flow.reducer';
import { setSyncState } from '../../redux/reducers/sync-state.reducer';
import { colors } from '../../theme/colors';
import { ErrorPlaceholder } from './ErrorPlaceholder';
import { limitedAccessCardsBoxStyles } from './constants';
import { fetchTgUser } from '../../services/telegram/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useAnalytics } from '../../analytics/context';
import * as consts from '../../App.const';
import { IframeLockWrapper } from './IframeLockWrapper';
import { useTelegramLock } from '../../services/telegram/lockContext';
import { useChats } from '../../hooks/useChats';

export const TelegramHiddenFrame = () => {
    const tg = useTelegram();
    const lock = useTelegramLock();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const cardId = useGetMatchedCardId();
    const [tgUserId, setTgUserId] = useState('');
    const { mutateAsync: logoutMutation } = useLogout();
    const queryClient = useQueryClient();
    const { token, user } = useSelector((state: RootStore) => state.auth);
    const { showTelegramLoginModal } = useSelector(
        (state: RootStore) => state.loginFlow,
    );
    const analytics = useAnalytics();
    const { isSynced } = useSelector((state: RootStore) => state.syncState);

    const isChat = pathname.includes('/chat') && !!user.workSpace;

    const matchedCard = useGetCardById(cardId);

    const isTeamChat = useMemo(
        () => user?.workSpace?.teamChat?.cardId === cardId,
        [user, cardId],
    );

    const isCardArchived = useMemo(() => {
        if (isTeamChat) {
            return false;
        }
        if (!matchedCard) {
            return true;
        }
        if (!isChat) {
            return false;
        }

        return matchedCard.status?.typeId === CardDefaultStatusEnum.ARCHIVE;
    }, [isChat, matchedCard, isTeamChat]);

    const chatTelegramId = useMemo(() => {
        return isTeamChat && user.workSpace?.teamChat?.id
            ? user.workSpace.teamChat.id
            : !matchedCard?.chatTelegramId || !isChat
              ? 0
              : matchedCard.chatTelegramId;
    }, [isChat, matchedCard, isTeamChat, user.workSpace?.teamChat?.id]);

    const cachedData = useSelector(
        (state: RootStore) => state.telegramState.chats[chatTelegramId],
    );

    const chat = useChats().getChatInfo(chatTelegramId.toString());

    const { accessToken, refreshToken } = token;

    const [statusNotSucceeded, setStatusNotSucceeded] = useState(false);
    const [statusConnected, setStatusConnected] = useState(false);

    const statusCheckCount = useRef(0);

    const handleAuthStateChange = async (
        state: TGStateUnauthenticatedUser | TGStateAuthenticatedUser,
    ) => {
        if (!state.authed && accessToken && refreshToken) {
            await logoutMutation({
                accessToken,
                refreshToken,
            });
            return;
        }
        if (state.authed && state.userId && tgUserId !== state.userId) {
            setTgUserId(state.userId);
        }

        if (state.authed && state.userId && showTelegramLoginModal) {
            dispatch(setShowTelegramModal(false));
            dispatch(
                setTelegramConnected({
                    isConnected: true,
                    userId: state.userId,
                }),
            );
        }
    };
    useEffect(() => {
        (async () => {
            if (!(tg && isSynced && tgUserId)) {
                return;
            }

            const savedIdentificatedUser = localStorage.getItem(
                IDENTIFICATED_USER_KEY,
            );
            const isNewUser = savedIdentificatedUser !== tgUserId;

            if (!isNewUser) {
                return;
            }

            const userResponse = await lock.scheduleEvent((tg) =>
                fetchTgUser(queryClient, tg, +tgUserId),
            );

            const user = userResponse?.userShortInfo;
            if (analytics && user) {
                analytics.identify(user.id, {
                    telegramId: user.id,
                    name: user.firstName + ' ' + user.lastName,
                    telegramUsername: user.usernames?.find(
                        (username) => username.isActive,
                    )?.username,
                });
            }
            localStorage.setItem(IDENTIFICATED_USER_KEY, tgUserId);
        })();
    }, [tg, isSynced, tgUserId]);

    const throttledCallback = useThrottleCallback(handleAuthStateChange, 1);

    useEffect(() => {
        const unSub = tg?.events.subscribe(
            'authStateChanged',
            throttledCallback,
        );

        return unSub;
    }, [tg, tg?.events, throttledCallback, showTelegramLoginModal]);

    useEffect(() => {
        const unSub = tg?.events.subscribe(
            'syncStateChanged',
            (state: TGSyncState) => {
                dispatch(setSyncState(state.isSynced));
            },
        );

        return unSub;
    }, [tg?.events]);

    useEffect(() => {
        const unSub = tg?.events.subscribe('loggedOut', () => {
            if (!accessToken || !refreshToken) {
                return;
            }
            logoutMutation({
                accessToken,
                refreshToken,
            });
        });

        return unSub;
    }, [tg?.events, accessToken, refreshToken]);

    useInterval(
        () => {
            statusCheckCount.current = statusCheckCount.current + 1;

            // @ts-expect-error: Do not rewrite to async
            tg?.status.proxy.check().then((res) => {
                if (res) {
                    setStatusConnected(true);
                }
            });

            if (statusCheckCount.current >= 10) {
                setStatusNotSucceeded(true);
            }
        },
        !statusNotSucceeded && !statusConnected ? 1000 : null,
    );

    const onReload = () => {
        setStatusNotSucceeded(false);
        statusCheckCount.current = 0;
    };

    const getWidth = useCallback(() => {
        switch (true) {
            case showTelegramLoginModal:
                return '400px';
            case !!isChat && !!chat:
                return '100%';

            default:
                return 0;
        }
    }, [isChat, chat, showTelegramLoginModal]);

    const width = getWidth();

    return (
        <Box
            id="tg-iframe-holder"
            zIndex={10}
            flexShrink={showTelegramLoginModal ? 0 : 1}
            width={width}
            visibility={isChat || showTelegramLoginModal ? 'initial' : 'hidden'}
            height={showTelegramLoginModal ? '680px' : '100%'}
            position="relative"
            sx={{
                ...(isChat && isCardArchived
                    ? limitedAccessCardsBoxStyles
                    : {}),
            }}
        >
            {statusNotSucceeded ? (
                <ErrorPlaceholder onReload={onReload} />
            ) : (
                <IframeLockWrapper iframeOpened={width !== 0}>
                    <iframe
                        id="telegram-iframe"
                        height="100%"
                        width="100%"
                        allow="clipboard-read; clipboard-write"
                        style={{
                            borderRadius: '4px',
                            border: `1px solid ${colors.gray[20]}`,
                        }}
                        src={buildTelegramIframeUrl(
                            TG_CONFIG.TG_IFRAME_URL,
                            consts.TEST_BRANCH,
                        )}
                    ></iframe>
                </IframeLockWrapper>
            )}
        </Box>
    );
};

const buildTelegramIframeUrl = (iframeUrl: string, branch?: string) => {
    const url = new URL(iframeUrl);
    if (branch) {
        url.searchParams.set('branch', branch);
    }
    return url.toString();
};

const IDENTIFICATED_USER_KEY = 'dise_june_identificated_user_id';
