import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { LegacyApiResponse } from '../axios';

export interface FolderChatSyncChatsMutationPayload {
	telegramChatId: string,
	name: string,
}

export interface SyncChatsMutationPayload {
	workspaceId: number;
	folders: {
		telegramFolderId: number;
		chats: FolderChatSyncChatsMutationPayload[];
	}[];
}

const syncChats = async (payload: SyncChatsMutationPayload) => {
	const { data } = await axios.post<LegacyApiResponse<number>>(
		'/api/cards/sync',
		payload,
	);

	return data.value;
};

const MUTATION_KEY_SYNC_CHATS = 'MUTATION_KEY_SYNC_CHATS';

function useSyncChatsMutation() {	
	return useMutation<number, unknown, SyncChatsMutationPayload>({
		mutationKey: [MUTATION_KEY_SYNC_CHATS],
		mutationFn: syncChats,
	});
}

export { syncChats, useSyncChatsMutation, MUTATION_KEY_SYNC_CHATS };
