import { Button, Link, Text } from '@chakra-ui/react';
import { Icon } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';
import { BOT_USERNAME } from '../../tg-config';

import css from './index.module.css';

import { useExchangeOtp, useGetOtp } from '@core/api';
import { sleep } from '../../utils';
import { useNavigate } from '@router';
import { setAuthStatus } from '../../redux/reducers/auth.reducer';
import { clearTgDataCache } from '../../redux/reducers/telegram-state.reducer';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTelegramLock } from '../../services/telegram/lockContext';
import { setShowTelegramModal } from '../../redux/reducers/login-flow.reducer';

export const Loader = () => 'Route loader';

export const Action = () => 'Route action';

export const Catch = () => <div>Something went wrong...</div>;

export const Pending = () => <div>Loading...</div>;

export default function LoginPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const lock = useTelegramLock();
	const queryClient = useQueryClient();

	const getOtpMutation = useGetOtp();
	const exchangeOtpMutation = useExchangeOtp();

	const { telegramConnected, userId } = useSelector(
		(state: RootStore) => state.loginFlow,
	);

	const [isCrmLoginLoading, setIsCrmLoginLoading] = useState(false);

	const handleConnectTelegram = () => {
		dispatch(setShowTelegramModal(true));
	};

	const handleCrmLogin = async () => {
		setIsCrmLoginLoading(true);

		try {
			if (!userId) {
				return;
			}

			const telegramUserId = Number(userId);

			const { otpCode } = await getOtpMutation.mutateAsync({
				telegramUserId,
			});

			await lock.scheduleBackgroundEvent(async tg =>
				tg?.actions.proxy.openChatByUsername({
					username: BOT_USERNAME,
					startParam: otpCode,
				}),
			);

			await sleep(3000);

			const { token, user } = await exchangeOtpMutation.mutateAsync({
				otpCode,
				telegramUserId,
			});

			dispatch(setAuthStatus({ token, user }));
			dispatch(clearTgDataCache());
			queryClient.resetQueries();
		} catch (error) {
			console.error(error);
		}

		setIsCrmLoginLoading(false);
	};

	return (
		<section className={css.layout}>
			<div className={css.content}>
				<Icon
					name={'logo'}
					width={'10rem'}
					height={'2.5rem'}
					strokeColor={'none'}
				/>

				<h2 className={css.title}>Log in</h2>

				<div className={css.controls}>
					<Text
						color="primary"
						fontSize="12px"
						lineHeight={'18px'}
						fontWeight={400}>
						Step 1:
					</Text>

					{!telegramConnected ? (
						<Button
							isDisabled={telegramConnected}
							onClick={handleConnectTelegram}>
							Connect Telegram
						</Button>
					) : (
						<Button isDisabled={true}>Telegram Connected</Button>
					)}

					<Text
						color="primary"
						fontSize="12px"
						lineHeight={'18px'}
						fontWeight={400}>
						Step 2:
					</Text>

					<Button
						isDisabled={!telegramConnected}
						isLoading={isCrmLoginLoading}
						onClick={handleCrmLogin}>
						Log in to CRM
					</Button>
				</div>

				<Text
					fontSize={'12px'}
					lineHeight={'18px'}
					textAlign={'center'}
					color={'tertiary'}>
					By using DISE, you agree to the{' '}
					<Link href="#" fontWeight={500}>
						Terms of Service
					</Link>{' '}
					and{' '}
					<Link href="#" fontWeight={500}>
						Data Processing Agreement
					</Link>
					.
				</Text>
			</div>
		</section>
	);
}
