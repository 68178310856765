import { QueryClient } from "@tanstack/react-query";
import { useQueryClient } from '@tanstack/react-query';

const queryClientForApi = new QueryClient({
  defaultOptions: {
      queries: {
          refetchOnWindowFocus: false,
      },
  },
});

const useQueryClientForApi = () => {
  return useQueryClient(queryClientForApi);
}

export { queryClientForApi, useQueryClientForApi };
