import { AnalyticsBrowser } from '@june-so/analytics-next';
import { createContext, PropsWithChildren, useContext } from 'react';

const AnalyticsContext = createContext<AnalyticsBrowser | null>(null);

interface AnalyticsContextProviderProps {
	writeKey: string;
}

export const AnalyticsContextProvider = ({
	writeKey,
	children,
}: PropsWithChildren<AnalyticsContextProviderProps>) => {
	if (process.env.NODE_ENV === 'production') {
		const analytics = AnalyticsBrowser.load({
			writeKey,
		});

		return (
			<AnalyticsContext.Provider value={analytics}>
				{children}
			</AnalyticsContext.Provider>
		);
	}

	return <>{children}</>;
};

export const useAnalytics = () => {
	const context = useContext(AnalyticsContext);

	return context;
};
