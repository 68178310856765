import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import API from '../api/api.base';
import { ApiResponse, TgFoldersResponseItem } from '../types';
import { QueryKeys } from '../constants';
import { RootStore } from '../redux/createStore';
import { useGetWorkspaceId } from '../hooks';

// REMOVE AFTER ONBOARDING FIX
/**
 * This query is used to get the folders from the database.
 */
export const useGetUserApiTgFolders = () => {
    const workspaceId = useGetWorkspaceId();

    const { isOnboarded } = useSelector((state: RootStore) => state.auth.user);

    return useQuery({
        queryKey: [QueryKeys.GET_TG_FOLDERS, workspaceId],
        queryFn: () =>
            API.get('/api/users/folders', { workspaceId }) as Promise<
                ApiResponse<{ folders: TgFoldersResponseItem[] }>
            >,
        refetchOnWindowFocus: false,
        enabled: isOnboarded,
        select: (data) => data.value.folders ?? [],
    });
};
