import { createStore, Provider } from 'jotai';
import React from 'react';

export const jotaiStore = createStore();

export const JotaiStoreProvider: React.FCC = ({ children }) => {
    return (
        <Provider store={jotaiStore}>
            {children}
        </Provider>
    );
};
