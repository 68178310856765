import { Outlet } from 'react-router-dom';
import Redirects from './_redirects';

export default function Layout() {
	return (
		<Redirects>
			<Outlet />
		</Redirects>
	);
}
