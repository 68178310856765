import React from 'react';
import { SelectWorkspaceModal } from '../../modals';

const SelectWorkspace: React.FC = () => {
	return (
		<>
			<SelectWorkspaceModal />
		</>
	);
};

export default SelectWorkspace;
