import { useSelector } from 'react-redux';
import { memo, PropsWithChildren } from 'react';
import { Navigate, Path } from '@router';
import { useLocation } from 'react-router-dom';
import { RootStore } from '../redux/createStore';

function AuthRedirects({ children }: PropsWithChildren) {
	const location = useLocation();

	const pathname = location.pathname as Path;

	const {	accessToken } = useSelector((state: RootStore) => state.auth.token);

	console.log('Auth-Redirects:', {pathname, accessToken});

	if (!accessToken && pathname !== '/login') return <Navigate to={'/login'} replace />;

	if (accessToken && pathname === '/login') return <Navigate to={'/'} replace />;

	return children;
}

export default memo(AuthRedirects);
