import {
	Box,
	TabIndicator,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
} from '@chakra-ui/react';
import { TabWithIcon } from '../../components';
import { TABS } from './constants';
import { NotificationsPanelTemplateTabs } from './tabs';
import { DefaultPageHeader } from '../../widgets';
import { NotificationStatusEnum } from './types';

const NotificationsPage = () => {
	return (
		<Box 
			as='section' 
			id='notifications-layout' 
			w="100%" 
			h='100%'
		>
			<DefaultPageHeader title="Notifications" iconName="bell" />

			<Box
				borderWidth="1px"
				borderColor="gray.20"
				borderRadius="8px"
				bg="white"
				h="100%"
				overflowX="auto"
				overflowY="hidden"
			>
				<Tabs isLazy={true} variant="unstyled" height="100%">
					<TabList
						px={2}
						borderBottomWidth="1px"
						borderBottomColor="gray.15">
						{TABS.map((icon, index) => (
							<TabWithIcon
								key={index}
								label={icon.label}
								iconName={icon.iconName}
							/>
						))}
					</TabList>
					<TabIndicator
						mt="-1.5px"
						height="1px"
						bg="primary"
						borderRadius="1px"
					/>
					<TabPanels height="100%" overflowY="auto">
						<TabPanel p={6}>
							<NotificationsPanelTemplateTabs
								key={1}
								filterId={NotificationStatusEnum.INBOX}
							/>
						</TabPanel>
						<TabPanel p={6}>
							<NotificationsPanelTemplateTabs
								key={2}
								filterId={NotificationStatusEnum.ARCHIVED}
							/>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
		</Box>
	);
};

export default NotificationsPage;
