import { MediaContent } from '../../../telegram-tt/src/api/types';
import { Maybe } from '../types';

const getContentByDocumentMediaType = (mediaType?: 'photo' | 'video') => {
    switch (mediaType) {
        case 'photo':
            return '🏞️ image';
        case 'video':
            return '🎞️ video';
        default:
            return '📄 file';
    }
};

export function getLastMessageContentLabel(media?: Maybe<MediaContent>) {
    for (const key in media) {
        switch (key) {
            case 'text':
                return media.text?.text || '';
            case 'photo':
                return '🏞️ image';
            case 'document':
                return getContentByDocumentMediaType(
                    media?.document?.mediaType,
                );
            case 'video':
            case 'altVideo':
                return media?.video?.isGif || media?.altVideo?.isGif
                    ? '🏞️ gif'
                    : '🎞️ video';
            case 'poll':
                return '📊 poll';
            case 'contact':
                return '👤 contact';
            case 'sticker':
                return '🙂 sticker';
            case 'audio':
                return '🎵 audio';
            case 'voice':
                return '🎙️ voice message';
            case 'location':
                return '📍 location';

            case 'invoice':
            case 'storyData':
            case 'giveaway':
            case 'giveawayResults':
            case 'game':
            case 'ttlSeconds':
            default:
                return '📎 attachment';
        }
    }
}
