import { memo, useEffect, useMemo, useState } from 'react';
import { Box, Grid } from '@chakra-ui/react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { KanbanBoardColumn } from './components';
import { BoardCardItemResponse, BoardFilterValue } from './types';
import {
    useGetWorkspaceCardsStatuses,
    useUpdateWorkspaceCardStatus,
} from '../../queries';
import {
    CardDefaultStatusEnum,
    CardStatusItem,
    TgDataForCard,
} from '../../types';
import { useUpdateBoardCardData } from './hooks';
import { useUpdateArchiveVisibility } from './queries';
import { moveTask, tasksAtom, TasksGroupedByStatus } from '@atoms/tasksAtom';
import { useAtom } from 'jotai';
import { LoadingOverlay, useLoadingProgress } from './loading-overlay';
import { useChats } from '../../hooks/useChats';

interface DashboardPanelTemplateTabsProps {
    tabIndex: BoardFilterValue;
    data: BoardCardItemResponse[];
    workspaceId?: number;
    chats: ReturnType<typeof useChats>;
    isArchiveVisible?: boolean;
}

const parseArrayByStatusId = (
    cards: BoardCardItemResponse[],
    statuses: CardStatusItem[],
) => {
    const cardsByStatus: TasksGroupedByStatus = {};

    statuses.forEach((status) => {
        cardsByStatus[status.id] = [];
    });

    // Group cards by status ID
    cards.forEach((card) => {
        const statusId = card.status.id;
        if (cardsByStatus.hasOwnProperty(statusId)) {
            cardsByStatus[statusId].push(card);
        }
    });

    return cardsByStatus;
};

const isObjEmpty = (obj: { [key: string]: BoardCardItemResponse[] }) =>
    Object.keys(obj).length === 0;

const DashboardPanelTemplateTabs = ({
    tabIndex,
    data,
    workspaceId,
    chats,
    isArchiveVisible,
}: DashboardPanelTemplateTabsProps) => {
    const { data: statusesData } = useGetWorkspaceCardsStatuses();
    const { updateBoardCardData } = useUpdateBoardCardData();
    const { mutateAsync: updateWorkspaceCardStatus } =
        useUpdateWorkspaceCardStatus();
    const { mutateAsync: updateArchiveVisibility } =
        useUpdateArchiveVisibility();

    useEffect(() => {
        if (chats.hasNextPage && !chats.isLoading) {
            setTimeout(() => {
                chats.fetchNextPage();
            }, 1000);
        }
    }, [chats.hasNextPage, chats.isLoading, chats]);

    const statuses = useMemo(
        () => statusesData?.value?.statuses,
        [statusesData],
    );
    const tasksDefault = useMemo(
        () => parseArrayByStatusId(data, statuses || []),
        [data, statuses],
    );

    useEffect(() => {
        setTasks(tasksDefault);
    }, []);

    const [tasks, setTasks] = useAtom(tasksAtom);

    const [prevData, setPrevData] = useState(JSON.stringify(data));

    useEffect(() => {
        if (!isObjEmpty(tasksDefault) && isObjEmpty(tasks)) {
            setTasks(tasksDefault);
        }
    }, [tasksDefault, tasks]);

    if (JSON.stringify(data) !== prevData) {
        setPrevData(JSON.stringify(data));
        setTasks(tasksDefault);
    }

    const onDragEnd = async (result: DropResult) => {
        const { source, destination } = result;

        // If dropped outside the droppable area or in the same column
        if (
            !destination ||
            !workspaceId ||
            source.droppableId === destination.droppableId
        ) {
            return;
        }

        const movedTask = tasks[source.droppableId][source.index];

        setTasks(
            moveTask({
                tasks,
                sourceStatusId: source.droppableId,
                destinationStatusId: destination.droppableId,
                sourceIndex: source.index,
                destinationIndex: destination.index,
            }),
        );
        const res = await updateWorkspaceCardStatus({
            workspaceId,
            cardId: movedTask.cardId,
            statusId: +destination.droppableId,
        });

        if (!res.success) {
            setTasks(tasks);
            return;
        }

        updateBoardCardData(movedTask.cardId, +destination.droppableId);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Box
                id="loading-overlay-holder"
                pos="relative"
                height="100%"
                width="100%"
                overflow="hidden"
            >
                <Grid
                    id="dashboard-grid"
                    width="100%"
                    height="100%"
                    overflow="auto"
                    templateColumns={`repeat(${statuses?.length}, minmax(280px, 1fr))`}
                >
                    {statuses?.map((status) => {
                        const isArchiveColumn =
                            status.typeId === CardDefaultStatusEnum.ARCHIVE;

                        return (
                            <KanbanBoardColumn
                                key={status.id}
                                chats={chats}
                                tabIndex={tabIndex}
                                columnStatus={status}
                                enableColumnIcon={isArchiveColumn}
                                isColumnIconActive={
                                    isArchiveColumn ? isArchiveVisible : false
                                }
                                onClickColumnIcon={() => {
                                    if (workspaceId && isArchiveColumn) {
                                        updateArchiveVisibility({
                                            workspaceId,
                                            isArchiveVisible: !isArchiveVisible,
                                        });
                                    }
                                }}
                            />
                        );
                    })}
                </Grid>

                <LoadingOverlay />
            </Box>
        </DragDropContext>
    );
};

export default memo(DashboardPanelTemplateTabs);
