import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { LegacyApiResponse } from '../axios';
import { queryClientForApi } from '../query-client';


export type Workspace = {
	id: number;
	name: string;
	avatarUrl: string;
	avatarThumbnailUrl: string;
};

const getCurrentUserWorkspaces = async () => {
	const { data } = await axios.get<LegacyApiResponse<Workspace[]>>(
		'/api/workspaces',
	);	

	return data.value;
};

const QUERY_KEY_GET_CURRENT_USER_WORKSPACES = 'QUERY_KEY_GET_CURRENT_USER_WORKSPACES';

const useGetCurrentUserWorkspacesQuery = () => {
	return useQuery<Workspace[]>(
		{
			queryKey: [QUERY_KEY_GET_CURRENT_USER_WORKSPACES],
			queryFn: getCurrentUserWorkspaces,
		},
		queryClientForApi
	);
};

export { getCurrentUserWorkspaces, useGetCurrentUserWorkspacesQuery, QUERY_KEY_GET_CURRENT_USER_WORKSPACES };
