import {
    useInfiniteQuery,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { useGetBoardCards } from '../features/Dashboard/queries';
import { useParams } from 'react-router-dom';
import { TelegramWindowContextType, useTelegram } from '../services';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    ApiChat,
    ApiMessage,
    ApiUser,
} from '../../../telegram-tt/src/api/types';
import { QueryKeys } from '../constants';
import { atom, useAtom } from 'jotai';
import { type NormalizedChatData } from '../../../telegram-tt/src/api/gramjs/methods/chats';
import { Maybe } from '../types';

function _getChatInfo(
    chatId: string,
    normalizedData: Maybe<NormalizedChatData>,
) {
    if (!normalizedData) return null;
    const { chats, messages, users, lastMessages } = normalizedData;

    if (!lastMessages || !messages || !users || !chats) return null;

    const lastMessageId = lastMessages[chatId];
    const lastMessage = lastMessageId ? messages[lastMessageId] : undefined;
    const lastMessageSender = lastMessage?.senderId
        ? users[lastMessage.senderId]
        : undefined;

    return chats[chatId]
        ? {
              ...chats[chatId],
              lastMessage: {
                  ...lastMessage,
                  sender: lastMessageSender,
              },
          }
        : null;
}

// Singleton promise to prevent multiple requests
let requestPromise:
    | ReturnType<
          TelegramWindowContextType['methods']['proxy']['fetchUserChats']
      >
    | undefined;

export const resetRequestPromise = () => {
    requestPromise = undefined;
};

export const getChats = async ({
    tg,
    workspaceChatIds,
    maxPages,
}: {
    tg?: TelegramWindowContextType;
    workspaceChatIds: string[];
    maxPages: number;
}) => {
    if (!tg) return null;
    if (requestPromise) return requestPromise;

    requestPromise = tg.methods.proxy
        .fetchUserChats(workspaceChatIds, maxPages)
        .finally(() => {
            requestPromise = undefined;
        });

    return requestPromise;
};

const lastMessageByChatIdAtom = atom<Record<string, number>>({});
const messagesAtom = atom<Record<string, ApiMessage>>({});
const usersAtom = atom<Record<string, ApiUser>>({});

export const useChats = () => {
    const tg = useTelegram();
    const { workspaceId } = useParams();
    const queryClient = useQueryClient();
    const { data: cards, isLoading } = useGetBoardCards();

    const workspaceChatIds = useMemo(() => {
        return cards?.map((card) => card.chatTelegramId.toString()) || [];
    }, [cards]);

    const chatsQueryLow = useQuery({
        queryKey: [QueryKeys.TG_CHATS, 'low', workspaceId],
        queryFn: () => getChats({ tg, workspaceChatIds, maxPages: 1 }),
        staleTime: 1000 * 60 * 60 * 8, // 8 hours
        enabled:
            !!tg && !isLoading && workspaceChatIds.length > 0 && !!workspaceId,
    });

    const chatsQueryAll = useQuery({
        queryKey: [QueryKeys.TG_CHATS, 'all', workspaceId],
        queryFn: () => getChats({ tg, workspaceChatIds, maxPages: 150 }),
        staleTime: 1000 * 60 * 60 * 8, // 8 hours
        enabled:
            !!tg &&
            !isLoading &&
            workspaceChatIds.length > 0 &&
            !!workspaceId &&
            !!chatsQueryLow.data,
    });

    const chatsQuery = useMemo(() => {
        return chatsQueryAll.data?.chats ? chatsQueryAll : chatsQueryLow;
    }, [chatsQueryLow.data, chatsQueryAll.data]);

    useEffect(() => {
        if (chatsQueryAll.data && !chatsQueryAll.data?.chats) {
            console.log('INVALIDATING CHATS QUERY ALL');
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.TG_CHATS, 'all', workspaceId],
            });
        }
    }, [chatsQueryAll.data]);

    const [, setLastMessageByChatId] = useAtom(lastMessageByChatIdAtom);
    const [, setMessages] = useAtom(messagesAtom);
    const [, setUsers] = useAtom(usersAtom);

    console.log('CHATS QUERY DATA', chatsQuery.data);

    useEffect(() => {
        console.time('SET ATOMS');
        setLastMessageByChatId(chatsQuery.data?.lastMessages || {});
        setMessages(chatsQuery.data?.messages || {});
        setUsers(chatsQuery.data?.users || {});
        console.timeEnd('SET ATOMS');
    }, [chatsQuery.data]);

    const getChatInfo = useCallback(
        (chatId: string) => _getChatInfo(chatId, chatsQuery.data),
        [chatsQuery.data],
    );

    const emptyData = {
        chats: {},
        users: {},
        messages: {},
        lastMessages: {},
    };

    return {
        queryData: chatsQuery.data,
        data: chatsQuery.data || emptyData,
        hasNextPage: false,
        fetchNextPage: () => {},
        isLoading: chatsQuery.isLoading,
        getChatInfo,
    };
};

/**
 * Forced to use this hook and extra atoms to ensure that the last message is updated in the state
 * and rendered in the UI
 *
 * Tried optimistic update but it didn't work as expected - the last message was not updated in the UI
 */
export const useLastMessageByChatId = (chatId?: string) => {
    const [lastMessageByChatId, setLastMessageByChatId] = useAtom(
        lastMessageByChatIdAtom,
    );
    const [messages, setMessages] = useAtom(messagesAtom);
    const [users] = useAtom(usersAtom);
    const lastMessageId = useMemo(
        () => (chatId ? lastMessageByChatId[chatId] : undefined),
        [chatId, lastMessageByChatId],
    );

    const lastMessage = useMemo(
        () => (lastMessageId ? messages[lastMessageId] : undefined),
        [lastMessageId, messages],
    );

    const lastMessageSender = useMemo(
        () => (lastMessage?.senderId ? users[lastMessage.senderId] : undefined),
        [lastMessage?.senderId, users],
    );

    const setMessage = useCallback(
        (chatId: string, lastMessage: ApiMessage) => {
            const lastMessageId = lastMessage.id;
            setLastMessageByChatId({
                ...lastMessageByChatId,
                [chatId]: lastMessageId,
            });
            setMessages({
                ...messages,
                [lastMessageId]: lastMessage,
            });
        },
        [lastMessageByChatId, messages, setLastMessageByChatId, setMessages],
    );

    return {
        message: {
            ...lastMessage,
            sender: lastMessageSender,
        },
        setMessage,
    };
};

export type ChatItem = ReturnType<ReturnType<typeof useChats>['getChatInfo']>;
