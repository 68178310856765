import { Button } from '@chakra-ui/react';
import { useState } from 'react';
import { useAtom } from 'jotai';
import Tree from 'rc-tree';
import { DataNode } from 'rc-tree/lib/interface';

import './tree-custom.css';
import css from './index.module.css';

import { selectedCardsAtom } from '../state';
import { useGetBoardCards } from '@features/Dashboard/queries';
import { useGetWorkspaceCardsStatuses } from '@queries/useGetWorkspaceCardsStatuses';
import { BoardCardItemResponse } from '@features/Dashboard/types';
import { Icon, switcherIcon } from './icons';

const statusKeyPrefix = 'status:';
const cardKeyPrefix = 'card:';
const checkedKeysLimit = 25;

interface BatchSendingSelectCardsProps {
  goToNext: () => void;
  goToPrevious: () => void;
  cancel: () => void;
}
function BatchSendingSelectCards({
  goToNext,
  cancel,
}: BatchSendingSelectCardsProps) {
  const [selectedCards, setSelectedCards] = useAtom(selectedCardsAtom);

  const [checkedKeys, setCheckedKeys] = useState<string[]>(selectedCards.map(({ cardId }) => `${cardKeyPrefix}${cardId}`));

  const getBoardCards = useGetBoardCards();

  const getWorkspaceCardsStatuses = useGetWorkspaceCardsStatuses();

  if (getBoardCards.isLoading || getWorkspaceCardsStatuses.isLoading) return <>Loading</>;

  if (getBoardCards.isError || getWorkspaceCardsStatuses.isError) return <>Error</>;

  if (!getBoardCards.data || !getWorkspaceCardsStatuses.data) return <>Empty</>;

  const { data: cards } = getBoardCards;
  
  const { statuses } = getWorkspaceCardsStatuses.data.value;

  const cardsByIdMap = cards.reduce((map, item) => map.set(`${item.cardId}`, item), new Map<string, BoardCardItemResponse>());

  const cardsByStatusIdMap = Map.groupBy(cards, ({ status }) => status.id);
  
  const treeData = statuses.reduce<DataNode[]>((acc, { id, name }) => {
    const cardsOfStatus = cardsByStatusIdMap.get(id);

    if (!cardsOfStatus?.length) return acc;

    return acc.concat({
      key: `${statusKeyPrefix}${id}`,
      title: name,
      children: cardsOfStatus.map(({ cardId, cardName }) => {                  
        return {
          key: `${cardKeyPrefix}${cardId}`,
          title: cardName
        };
      }),
    });
  }, []);

  const checkedCardKeys = checkedKeys.filter((id) => id.startsWith(cardKeyPrefix));

  const handleCheck = (checkedKeys: string[]) => {
    setCheckedKeys(checkedKeys);
  };

  const handleGoToNext = () => {
    if (!checkedKeys.length) return;

    const selectedCards = checkedKeys.reduce<BoardCardItemResponse[]>((acc, id) => {
      const card = cardsByIdMap.get(id.replace(cardKeyPrefix, ''));

      if (card) return acc.concat([card]);

      return acc;
    }, []);
    
    setSelectedCards(selectedCards);

    goToNext();
  };

  const handleCancel = () => {
    cancel();
  };
  
	return (
    <>
      <header className={css.header}>
        <h3 className={css.subtitle}>
          Select Audience
        </h3>

        <p className={css.hint}>
          You can also select chats directly on the dashboard
        </p>
      </header>

      <div className={css.selection}>
        <Tree
          checkable={true}
          selectable={false}
          defaultExpandAll={true}
          treeData={treeData}
          checkedKeys={checkedKeys}
          onCheck={(checkedKeys) => handleCheck(checkedKeys as string[])}
          switcherIcon={switcherIcon}
          icon={Icon}
          className={css.tree}
        />

        <div className={css.total}>
          {checkedCardKeys.length} chats selected (limited to: {checkedKeysLimit})
        </div>
      </div>

      <footer className={css.footer}>
        <Button 
          variant='outline'
          isDisabled={(
            !checkedCardKeys.length ||
            checkedCardKeys.length > checkedKeysLimit
          )}
          title={(
            !checkedCardKeys.length ||
            checkedCardKeys.length > checkedKeysLimit
          )
            ? `You must select at least one chat, limited to ${checkedKeysLimit}` 
            : 'Continue to next step'
          }
          className={css.continueButton}
          onClick={handleGoToNext}
        >
          Continue
        </Button>

        <Button 
          variant='ghost' 
          onClick={handleCancel}
        >
          Return to dashboard
        </Button>
      </footer>
    </>
  );
}

export default BatchSendingSelectCards;
