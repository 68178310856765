import { Box, Button, Checkbox, Flex, Spinner, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { WarningBannerWrapper } from "../../../modals/Onboarding/components";
import { useSelector } from 'react-redux';
import { RootStore } from "../../../redux/createStore";
import { useTelegramLock } from "../../../services/telegram/lockContext";

// reimport with reexported local type
import { ApiChat, ApiChatFolder } from "../../../../../telegram-tt/src/api/types";

import css from './index.module.css'
import { useAtomValue } from "jotai";
import { createdWorkspaceIdAtom } from "../state";
import { FolderChatSyncChatsMutationPayload, useGetCurrentUserQuery, useSyncChatsMutation } from "@core/api";

interface OnboardingSyncChatsProps {
  goToNext: () => void;
  goToPrevious: () => void;
  cancel: () => void;
}
function OnboardingSyncChats({}: OnboardingSyncChatsProps) {
    const lock = useTelegramLock();

    const createdWorkspaceId = useAtomValue(createdWorkspaceIdAtom);

    const { isSynced } = useSelector((state: RootStore) => state.syncState);

    const [isChatFoldersLoading, setIsChatFoldersLoading] = useState<boolean>(true);

    const [chatFolders, setChatFolders] = useState<{
        byId: Record<number, ApiChatFolder>;
        orderedIds: number[];
    }>({
        byId: {},
        orderedIds: []
    });

    const [selectedChatFoldersIds, setSelectedChatFoldersIds] = useState<number[]>([]);

    const getCurrentUserQuery = useGetCurrentUserQuery();
    const syncChatsMutation = useSyncChatsMutation();

    useEffect(() => {
        if (!isSynced) return;

        lock.scheduleBackgroundEvent(async tg => {
            try {
                setIsChatFoldersLoading(true);
    
                const chatFolders = await tg.methods.proxy.fetchChatFolders();
    
                if (!chatFolders) return;
            
                setChatFolders(chatFolders);
    
                setIsChatFoldersLoading(false);
            } catch (error) {
                console.log(error);
            }
        });
    }, [isSynced]);

    if (!isSynced || isChatFoldersLoading) return (
        <Flex h={'100%'} w={'100%'} minH={'25rem'} alignItems={'center'} justifyContent={'center'}>
            <Spinner size={'xl'} />
        </Flex>
    );

    const handleContinue = async () => {
        try {
            await lock.scheduleBackgroundEvent(async tg => {        
                if (!createdWorkspaceId) return;

                const chatsIds = selectedChatFoldersIds.flatMap((folderId) => {
                    return chatFolders.byId[folderId].includedChatIds;
                });

                const resolvedChatsInfos = await Promise.allSettled(
                    chatsIds.map((chatId) => tg.custom.proxy.getChatById(Number(chatId)))
                );

                const chatsByIds = resolvedChatsInfos.reduce<Record<string, ApiChat>>((acc, chat) => {                    
                    /**
                     * If no "value" presented, that means that 
                     * "tg.custom.proxy.getChatById" returned "undefined".
                     * As usual that means that user is not presented in this chat.
                     * But ID of this chat can still be presented in user folder! 
                     * E.g. "includedChatIds" array.
                     */
                    if (chat.status === 'fulfilled' && chat.value?.chatShortInfo) {
                        acc[chat.value.chatShortInfo.id] = chat.value.chatShortInfo;
                    }
                    return acc;
                }, {});

                // NOTE: Possible feature is to omit "name: chatsByIds[chatId].title" and resolve it on the dashboard by ID.
                const foldersPayload = selectedChatFoldersIds.map((folderId) => {      
                    return {
                        telegramFolderId: folderId,
                        chats: chatFolders.byId[folderId].includedChatIds.reduce<FolderChatSyncChatsMutationPayload[]>(
                            (acc, chatId) => {
                                // Skip unresolved chats.
                                if (!chatsByIds[chatId]) return acc;

                                return acc.concat([{
                                    telegramChatId: chatId,
                                    name: chatsByIds[chatId].title,
                                }]);
                            }
                        , [])
                    };
                });

                const payload = {
                    workspaceId: createdWorkspaceId,
                    folders: foldersPayload
                };
                
                await syncChatsMutation.mutateAsync(payload);
                
                /**
                 * NOTE: Based on legacy BS backend, no manual navigation for now.
                 * But after "syncChatsMutation" call, user data on backend setted like:
                 *   isOnboarder: true;
                 *   workspace: [createdWorkspace];
                 * And after that, revalidation of getCurrentUserQuery will trigger redirection logic in "_redirects.tsx"...
                */
                // navigate('/:workspaceId', { params: { workspaceId: String(createdWorkspaceId) } });
                await getCurrentUserQuery.refetch();
            });
        } catch (error) {
            console.log(error);
        }
    }

    const handleSkip = async () => {
        if (!createdWorkspaceId) return;

        await syncChatsMutation.mutateAsync({
            workspaceId: createdWorkspaceId,
            folders: [],
        });

        await getCurrentUserQuery.refetch();
    };

    const handleCheckChatFolder = (chatFolderId: number) => {
        setSelectedChatFoldersIds((previousState) => {
            if (previousState.includes(chatFolderId)) return previousState.filter((id) => id !== chatFolderId);
            return [...previousState, chatFolderId];
        });
    };

    const nonEmptyCustomOnlyChatFolders = chatFolders.orderedIds.reduce<ApiChatFolder[]>((acc, id) => {
        if (chatFolders.byId[id]) return acc.concat(chatFolders.byId[id]);
        return acc;
    }, []);
    
	return (
        <Flex flexDirection="column" align="center" gap="30px">
            <Box>
                <Text
                    fontWeight="600"
                    fontSize="20px"
                    lineHeight="24px"
                    textAlign="center"
                >
                    Chats sync
                </Text>
                <Text textAlign="center" mt="16px" color="secondary">
                    Sync your Telegram chats with DISE by choosing folders that
                    you want to track.
                </Text>
            </Box>

            <Flex
                flexDirection="column"
                align="start"
                borderRadius="8px"
                border="1px solid"
                borderColor="gray.20"
                maxH="260px"
                overflowY="auto"
                bg="gray.10"
                w={'100%'}
            >
                {nonEmptyCustomOnlyChatFolders.length > 0 && nonEmptyCustomOnlyChatFolders.map((chatFolder) => {
                    return (
                        <Checkbox
                            key={chatFolder.id}
                            isChecked={selectedChatFoldersIds.includes(chatFolder.id)}
                            onChange={() => handleCheckChatFolder(chatFolder.id)}
                            className={css.chatFolderCheckbox}
                        >
                            {chatFolder.title}
                        </Checkbox>
                    )
                })}
            </Flex>

            <VStack w="full" align="flex-start" spacing={2}>
                <WarningBannerWrapper iconName="info-circle">
                    <Text
                        fontSize="11px"
                        color="secondary"
                        fontWeight="400"
                        lineHeight="16px"
                    >
                        <span>
                            We don&apos;t have access to your messages.
                        </span>{' '}
                        DISE is a Telegram client which displays messages served
                        from Telegram servers and use local cache to enable CRM
                        functionality (like “unanswered”). Only synced chats
                        appear in DISE.
                    </Text>
                </WarningBannerWrapper>
            </VStack>

            <Button
                w="full"
                maxW="200px"
                alignSelf="center"
                boxShadow="0px 2px 4px 0px #0000000A, 0px 0px 4px 0px #00000014"
                isDisabled={selectedChatFoldersIds.length === 0}
                isLoading={syncChatsMutation.isPending}
                onClick={handleContinue}
            >
                Continue
            </Button>

            <Button
				w={'65%'}
                variant={'ghost'}
				onClick={handleSkip}
			>
				Skip
			</Button>
        </Flex>
    );
}

export default OnboardingSyncChats;
