import { get, set, del } from 'idb-keyval';
import {
    PersistedClient,
    Persister,
} from '@tanstack/react-query-persist-client';

/**
 * Creates an Indexed DB persister
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 */
export function createIDBPersister(idbValidKey: IDBValidKey = 'reactQuery') {
    return {
        persistClient: async (client: PersistedClient) => {
            try {
                const serializableClient = JSON.parse(JSON.stringify(client));
                await set(idbValidKey, serializableClient);
            } catch (error) {
                console.error('Failed to persist query client:', error);
                // Optionally throw or handle the error as needed
            }
        },
        restoreClient: async () => {
            try {
                return await get<PersistedClient>(idbValidKey);
            } catch (error) {
                console.error('Failed to restore query client:', error);
                return null;
            }
        },
        removeClient: async () => {
            try {
                await del(idbValidKey);
            } catch (error) {
                console.error('Failed to remove query client:', error);
            }
        },
    } as Persister;
}
