import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from '../api/api.base';
import {
    ApiResponseEmpty,
    UpdateCardTrackStatusPayload,
    ApiResponse,
} from '../types';
import { Alerter } from '../utils';
import { QueryKeys } from '../constants';
import { BoardCardItemResponse } from '../features/Dashboard/types';

export const useUpdateCardTrackStatus = (silent: boolean) => {
    const queryClient = useQueryClient();
    return useMutation<ApiResponseEmpty, unknown, UpdateCardTrackStatusPayload>(
        {
            mutationKey: [QueryKeys.UPDATE_CARD_TRACK_STATUS],
            mutationFn: (payload) => {
                const {
                    workspaceId,
                    cardId,
                    trackedLastTelegramMessageUnixTime,
                } = payload;
                return API.put(
                    `/api/workspaces/${workspaceId}/cards/${cardId}/track`,
                    {
                        trackedLastTelegramMessageUnixTime,
                    },
                );
            },
            onSuccess: (data, variables) => {
                if (data.success) {
                    if (!silent) {
                        Alerter.success(
                            data?.messages?.[0]?.message ||
                                'Status successfully changed',
                        );
                    }
                    queryClient.setQueryData<
                        ApiResponse<{ cards: BoardCardItemResponse[] }>
                    >(
                        [QueryKeys.GET_BOARD_CARDS, variables.workspaceId],
                        (oldData) => {
                            if (oldData) {
                                const updatedCards = oldData.value.cards.map(
                                    (card) => {
                                        if (card.cardId !== variables.cardId) {
                                            return card;
                                        }

                                        return {
                                            ...card,
                                            trackedLastTelegramMessageUnixTime:
                                                variables.trackedLastTelegramMessageUnixTime,
                                        };
                                    },
                                );
                                return {
                                    ...oldData,
                                    value: {
                                        cards: updatedCards,
                                    },
                                };
                            }
                            return oldData;
                        },
                    );
                }
                //  else {
                // 	Alerter.error(
                // 		data?.errors?.[0]?.message || 'Error occurred',
                // 	);
                // }
                // Alerter.success('Card status changed successfully!');
            },
        },
    );
};
