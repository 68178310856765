import { atom } from 'jotai';
import { jotaiStore } from '../jotai/store';
import { flagSchema, FlagsSchema } from './schema';
import { defaultConfig } from './defaultConfig';
import { APP_ENV } from '../App.const';

const initialFlags = defaultConfig[APP_ENV];
export const flagsAtom = atom<FlagsSchema>(initialFlags);

export const parseFlagsFromHash = () => {
    const params = new URLSearchParams(window.location.hash.substring(1));

    const res = flagSchema.partial().safeParse(Object.fromEntries(params));

    if (res.success) {
        setFlags(res.data);
    } else {
        console.error('Invalid flags: ', res.error);
        alert('Invalid flags. See console for details.');
    }
};

export const getFlags = () => jotaiStore.get(flagsAtom);

export const setFlags = (newFlags: Partial<FlagsSchema>) =>
    jotaiStore.set(flagsAtom, { ...initialFlags, ...newFlags });
