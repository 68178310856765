import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { LegacyApiResponse } from '../axios';

export type TelegramUserToInvite = {
	telegramUserId: number;
	firstName: string;
	lastName: string | null;
};

type InviteMembersToWorkspacePayload = {
	telegramUsers: TelegramUserToInvite[]
}

type InviteMembersToWorkspaceResult = {
	invitedUsersIds: number[];
	notInvitedUsers: string[];
}

const inviteMembersToWorkspace = async (workspaceId: number, telegramUsers: TelegramUserToInvite[]) => {
	const { data } = await axios.post<LegacyApiResponse<InviteMembersToWorkspaceResult>>(
		`/api/workspaces/${workspaceId}/invite`, {
			telegramUsers,
		}
	);

	return data.value;
};

const MUTATION_KEY_INVITE_MEMBERS_TO_WORKSPACE = 'MUTATION_KEY_INVITE_MEMBERS_TO_WORKSPACE';

function useInviteMembersToWorkspaceMutation(workspaceId: number) {
	return useMutation<InviteMembersToWorkspaceResult,unknown, InviteMembersToWorkspacePayload>({
		mutationKey: [MUTATION_KEY_INVITE_MEMBERS_TO_WORKSPACE],
		mutationFn: ({ telegramUsers }) => inviteMembersToWorkspace(workspaceId, telegramUsers),
	});
}
 
export { 
	inviteMembersToWorkspace, 
	useInviteMembersToWorkspaceMutation,
	MUTATION_KEY_INVITE_MEMBERS_TO_WORKSPACE 
};
