import { useMemo, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { Loader } from '../../components';
import { getBoardTabs } from './constants';
import { BoardTab, LabelsSelectPopup } from './components';
import DashboardPanelTemplateTabs from './DashboardPanelTemplate.tabs';
import { useGetBoardCards } from './queries';
import { RootStore } from '../../redux/createStore';
import { useGetWorkspaceId } from '../../hooks';
import { BoardFilterValue } from './types';
import { ApiResponse } from '../../types';
import { QueryKeys } from '../../constants';
import {
    getUnreadCards,
    getUnansweredCards,
    getMentionedCards,
    getActiveTasksCards,
    getTeamActivityCards,
} from '../../utils';
import { DefaultPageHeader } from '../../widgets';
import { useSearchParams } from 'react-router-dom';
import { useChats } from '../../hooks/useChats';

const DashboardPage = () => {
    const queryClient = useQueryClient();

    const workspaceId = useGetWorkspaceId();

    const isArchiveVisible = useSelector(
        (state: RootStore) => state.auth.user.workSpace?.isArchiveVisible,
    );

    const chats = useChats();

    const [searchParams] = useSearchParams();

    const labelIdSearchParam = searchParams.get('labelId');

    const activeLabelId = labelIdSearchParam ? Number(labelIdSearchParam) : 0;

    const { data, isLoading } = useGetBoardCards();

    const teamTelegramIds = queryClient.getQueryData<
        ApiResponse<{ telegramUserIds: number[] }>
    >([QueryKeys.GET_WORKSPACE_TELEGRAM_IDS, workspaceId]);

    const [tabIndex, setTabIndex] = useState(BoardFilterValue.All);

    const renderTabs = useMemo(() => {
        return getBoardTabs({
            unread:
                getUnreadCards({
                    cards: data || [],
                    chats,
                    activeLabelId,
                }).length || 0,

            unanswered:
                getUnansweredCards({
                    cards: data || [],
                    chats,
                    teamTelegramIds:
                        teamTelegramIds?.value?.telegramUserIds || [],
                    activeLabelId,
                }).length || 0,
            activeTasks:
                getActiveTasksCards({
                    cards: data || [],
                    activeLabelId,
                }).length || 0,
            teamActivity:
                getTeamActivityCards({
                    cards: data || [],
                    activeLabelId,
                }).length || 0,

            mentions:
                getMentionedCards({
                    cards: data || [],
                    activeLabelId,
                }).length || 0,
        }).map((tab, index) => (
            <BoardTab
                key={tab.id}
                isActive={tab.filterValue === tabIndex}
                onClick={() => setTabIndex(index)}
                {...tab}
            />
        ));
    }, [
        tabIndex,
        data,
        activeLabelId,
        chats,
        teamTelegramIds?.value?.telegramUserIds,
    ]);

    return (
        <Flex
            as="section"
            id="dashboard-layout"
            w="100%"
            h="100%"
            flexDir="column"
        >
            <DefaultPageHeader
                title="Main Pipeline"
                iconName="users"
                isSyncSettingsEnabled={true}
                isInviteMembersEnabled={true}
            />

            {isLoading ? (
                <Loader centerHeight="100%" />
            ) : (
                <Flex
                    as="section"
                    flexDir="column"
                    borderWidth="1px"
                    borderColor="gray.20"
                    borderRadius="8px"
                    overflow="hidden"
                    bg="white"
                    h="100%"
                >
                    <Flex
                        as="header"
                        flexShrink={0}
                        columnGap={4}
                        p={2}
                        overflowX="auto"
                        borderBottomWidth="1px"
                        borderBottomColor="gray.15"
                    >
                        {renderTabs}

                        <LabelsSelectPopup />
                    </Flex>

                    <DashboardPanelTemplateTabs
                        tabIndex={tabIndex}
                        data={data || []}
                        workspaceId={workspaceId}
                        chats={chats}
                        isArchiveVisible={isArchiveVisible}
                    />
                </Flex>
            )}
        </Flex>
    );
};

export default DashboardPage;
