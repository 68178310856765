import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, useSteps } from '@chakra-ui/react';
import { useModals } from '@router';

import css from './index.module.css';

import { enteredMessageAtom, selectedCardsAtom } from './state';
import BatchSendingSelectCards from './select-cards';
import BatchSendingEnterMessage from './enter-message';
import BatchSendingLaunchProcess from './launch-process';
import { useAtom } from 'jotai';
import { useUnsavedChangesChecker } from '../../hooks';

const steps = [
  { key: 'selector', Content: BatchSendingSelectCards },
  { key: 'message', Content: BatchSendingEnterMessage },
  { key: 'process', Content: BatchSendingLaunchProcess },
];

function BatchSending() {
  const modals = useModals();

  const [selectedCards, setSelectedCards] = useAtom(selectedCardsAtom);
  const [enteredMessage, setEnteredMessage] = useAtom(enteredMessageAtom);

  useUnsavedChangesChecker(!!selectedCards.length || !!enteredMessage.length);

  const { activeStep,  goToNext, goToPrevious } = useSteps({
    index: 0,
    count: steps.length,
  });

  const currentStep = steps[activeStep];

  const CurrentStepComponent = currentStep.Content;

  const handleCancel = () => {
    modals.close();

    setSelectedCards([]);
    setEnteredMessage('');
  };

	return (
    <Modal 
      isOpen={true}
      isCentered={true}
      onClose={handleCancel}
    >
      <ModalOverlay />

      <ModalContent className={css.content}>
        <ModalHeader className={css.header}>
          <h2 className={css.title}>
            Batch Send
          </h2>

          <span className={css.stepsCount}>
            Step {activeStep + 1}/3
          </span>
        </ModalHeader>

        <ModalBody className={css.body}>
          <CurrentStepComponent
            key={currentStep.key}
            goToNext={goToNext} 
            goToPrevious={goToPrevious}
            cancel={handleCancel}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default BatchSending;
