/* eslint-disable indent */

import { BoardCardItemResponse } from '../features/Dashboard/types';
import { useChats } from '../hooks/useChats';
import { CardDefaultStatusEnum, LabelItemType } from '../types';

interface BaseCardsPayload {
    cards: BoardCardItemResponse[];
    statusId?: number;
    activeLabelId?: number;
}

interface UnreadCardsPayload extends BaseCardsPayload {
    chats: ReturnType<typeof useChats>;
}

interface UnansweredCardsPayload extends UnreadCardsPayload {
    teamTelegramIds: number[];
}

const checkLabelsForSelectedId = (labels: LabelItemType[], id: number) =>
    labels?.some((label) => label.workspaceLabelId === id) ?? false;

const filterCardsByLabel = (
    cards: BoardCardItemResponse[],
    activeLabelId?: number,
) =>
    activeLabelId
        ? cards.filter((card) =>
              checkLabelsForSelectedId(card.labels, activeLabelId),
          )
        : cards;

const isNotArchived = (card: BoardCardItemResponse) =>
    card.status.typeId !== CardDefaultStatusEnum.ARCHIVE;

const filterByStatus = (card: BoardCardItemResponse, statusId?: number) =>
    statusId ? card.status.id === statusId : true;

export const getAllCards = ({
    cards,
    activeLabelId,
}: Omit<BaseCardsPayload, 'statusId'>) =>
    filterCardsByLabel(cards, activeLabelId);

export const getUnreadCards = ({
    cards,
    chats,
    statusId,
    activeLabelId,
}: UnreadCardsPayload) =>
    filterCardsByLabel(cards, activeLabelId).filter((card) => {
        const tgChat = chats?.getChatInfo(card.chatTelegramId.toString());
        return (
            tgChat?.unreadCount &&
            isNotArchived(card) &&
            filterByStatus(card, statusId)
        );
    });

export const getUnansweredCards = ({
    cards,
    chats,
    teamTelegramIds,
    statusId,
    activeLabelId,
}: UnansweredCardsPayload) =>
    filterCardsByLabel(cards, activeLabelId).filter((card) => {
        const tgChat = chats?.getChatInfo(card.chatTelegramId.toString());
        const isLastMessageFromTeam = teamTelegramIds.includes(
            +(tgChat?.lastMessage.sender?.id ?? 0),
        );
        return (
            !!tgChat &&
            !isLastMessageFromTeam &&
            card.trackedLastTelegramMessageUnixTime !==
                tgChat?.lastMessage?.date &&
            isNotArchived(card) &&
            filterByStatus(card, statusId)
        );
    });

export const getMentionedCards = ({
    cards,
    statusId,
    activeLabelId,
}: BaseCardsPayload) =>
    filterCardsByLabel(cards, activeLabelId).filter(
        (card) =>
            card.message?.unreadCount &&
            card.currentUserTagged &&
            isNotArchived(card) &&
            filterByStatus(card, statusId),
    );

export const getActiveTasksCards = ({
    cards,
    statusId,
    activeLabelId,
}: BaseCardsPayload) =>
    filterCardsByLabel(cards, activeLabelId).filter(
        (card) =>
            card?.tasksCount &&
            isNotArchived(card) &&
            filterByStatus(card, statusId),
    );

export const getTeamActivityCards = ({
    cards,
    statusId,
    activeLabelId,
}: BaseCardsPayload) =>
    filterCardsByLabel(cards, activeLabelId).filter(
        (card) =>
            card?.message?.unreadCount &&
            isNotArchived(card) &&
            filterByStatus(card, statusId),
    );
