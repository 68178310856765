/* eslint-disable indent */

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { ApiResponse, BaseUserData } from '../types';
import { QueryKeys } from '../constants';
import { RootStore } from '../redux/createStore';
import { useGetMatchedWorkspaceId } from './useGetWorkspaceId';
import { useChats } from './useChats';

export const useTeamMembersList = (chatTelegramId: number) => {
    const queryClient = useQueryClient();
    const workspaceId = useGetMatchedWorkspaceId();

    const chats = useChats();
    const cachedData = useSelector(
        (state: RootStore) => state.telegramState.chats[chatTelegramId],
    );
    const storeAvatars = useSelector((state: RootStore) => state.avatars);

    const allTeamMembersData = queryClient.getQueryData<
        ApiResponse<{ members: BaseUserData[] }>
    >([QueryKeys.GET_WORKSPACE_MEMBERS, workspaceId]);

    const chat = chats.getChatInfo(chatTelegramId.toString());

    const chatTeamMembers = useMemo(() => {
        const allTeamMembers = allTeamMembersData?.value?.members || [];
        const cachedChatMembers = chat?.chatMembers?.map((member) => member.id);

        return cachedChatMembers?.length
            ? allTeamMembers
                  ?.filter((member) =>
                      cachedChatMembers.includes(member.telegramUserId || 0),
                  )
                  .map((user) => ({
                      ...user,
                      avatar: storeAvatars[`${user.telegramUserId}`],
                  })) || []
            : allTeamMembers.map((user) => ({
                  ...user,
                  avatar: storeAvatars[`${user.telegramUserId}`],
              })) || [];
    }, [cachedData, allTeamMembersData?.success]);

    const allTeamMembersWithAvatars = useMemo(() => {
        const allTeamMembers = allTeamMembersData?.value?.members || [];

        return (
            allTeamMembers.map((user) => ({
                ...user,
                avatar: storeAvatars[`${user.telegramUserId}`],
            })) || []
        );
    }, [allTeamMembersData?.success]);

    const chatTeamMemberIds = useMemo(
        () =>
            chatTeamMembers
                .map((member) => member.telegramUserId)
                .filter(
                    (telegramUserId) => telegramUserId !== undefined,
                ) as number[],
        [chatTeamMembers],
    );

    const chatTeamMembersTgNicknames = useMemo(() => {
        const allTeamMembers = allTeamMembersData?.value?.members || [];

        const cachedChatMembers = cachedData?.chatMembers;

        const teamMembers = new Map(
            allTeamMembers.map((obj) => [obj.telegramUserId, obj]),
        );

        return cachedChatMembers?.length
            ? cachedChatMembers
                  .filter((member) => teamMembers.has(member.id))
                  .map((user) => user.username)
            : [];
    }, [cachedData, allTeamMembersData?.success]);

    return {
        chatTeamMembers,
        chatTeamMemberIds,
        chatTeamMembersTgNicknames,
        isUserHasChatAccess: !!chat,
        allTeamMembersWithAvatars,
    };
};
