import React, { useMemo } from 'react';
import { Box, HStack, Skeleton, Text, VStack } from '@chakra-ui/react';
import { EventsIndicator } from '../../EventsIndicator/EventsIndicator';
import { LabelWithImage } from '../../LabelWithImage/LabelWithImage';
import { getAvatarBg, getLastMessageContentLabel } from '../../../utils';
import { Maybe, TgLastMessage } from '../../../types';
import { useAvatar } from '../hooks';
import { ApiUser } from '../../../../../telegram-tt/src/api/types';

interface CardBodyProps {
    unreadCount?: number;
    messageContent?: Maybe<TgLastMessage['content']>;
    name?: string;
    isLoaded?: boolean;
    chatId: string;
    chatTelegramId: number;
    senderData?: Maybe<ApiUser>;
    isEnableFetchSenderAvatar: boolean;
}

export const CardBody: React.FC<CardBodyProps> = ({
    unreadCount,
    messageContent,
    name,
    isLoaded,
    chatId,
    senderData,
}) => {
    const avatar = useAvatar(senderData?.id, senderData?.avatarHash);

    const messageContentLabel = useMemo(
        () => getLastMessageContentLabel(messageContent),
        [messageContent],
    );

    return (
        <VStack
            w="100%"
            align="flex-start"
            isTruncated={true}
            spacing={1}
            pb={1.5}
        >
            <HStack
                w="100%"
                spacing={1}
                justify="space-between"
                position="relative"
            >
                <Skeleton
                    isLoaded={isLoaded}
                    maxW="100%"
                    w="100%"
                    startColor="gray.40"
                    endColor="gray.20"
                    h={isLoaded ? 'inherit' : '20px'}
                >
                    <HStack
                        flex={1}
                        pr={unreadCount ? '16px' : '0px'}
                        isTruncated={true}
                    >
                        <LabelWithImage
                            label={name || ''}
                            imageLink={avatar}
                            includeAvatar={true}
                            avatarProps={{
                                bg: getAvatarBg(Number(chatId)),
                            }}
                        />
                    </HStack>
                </Skeleton>
                <EventsIndicator
                    count={unreadCount || 0}
                    wrapperProps={{
                        position: 'absolute',
                        right: 0,
                        bg: 'transparentLight.10',
                    }}
                    textProps={{
                        color: 'secondary',
                    }}
                />
            </HStack>
            {messageContent ? (
                <Skeleton
                    maxW="100%"
                    w="100%"
                    h={isLoaded ? 'inherit' : '20px'}
                    startColor="gray.40"
                    endColor="gray.20"
                    isLoaded={isLoaded}
                >
                    <Box mt={1} maxW="100%">
                        <Text isTruncated={true}>{messageContentLabel}</Text>
                    </Box>
                </Skeleton>
            ) : null}
        </VStack>
    );
};
